@import url(https://db.onlinewebfonts.com/c/a59a10fc173a405262ecba082980066e?family=Aktiv+Grotesk);




/*@font-face {font-family: "Aktiv Grotesk";
    src: url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.eot"); 
    src: url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.eot?#iefix") format("embedded-opentype"), 
    url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.ttf") format("truetype"), 
    url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.svg#Aktiv Grotesk") format("svg"); 
}*/


@font-face {font-family: "Aktiv Grotesk";
    src: url('/fonts/a59a10fc173a405262ecba082980066e.eot'); /* IE9*/
    src: url('/fonts/a59a10fc173a405262ecba082980066e.eot') format("embedded-opentype"), /* IE6-IE8 */
    url("/fonts/a59a10fc173a405262ecba082980066e.woff2") format("woff2"), /* chrome firefox */
    url("/fonts/a59a10fc173a405262ecba082980066e.woff") format("woff"), /* chrome firefox */
    url("/fonts/a59a10fc173a405262ecba082980066e.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
   url("https://db.onlinewebfonts.com/t/a59a10fc173a405262ecba082980066e.svg#Aktiv Grotesk") format("svg"); /* iOS 4.1- */
}


body {margin:0px; padding:0px; font-size: 16px; color: #a3a4a9;
   font-family:"aktiv-grotesk",sans-serif; position: relative; }


a{ font-size: 15px; text-decoration: none !important; }

.form-control:focus {box-shadow:none;}
.divh1 .ptag p{ font-size: 15px; padding: 0; margin: 0;}
.divh1 h1{}
.divh3 h3{ font-size: 20px; padding: 0; margin: 0; }
.divh4 h4{ font-size: 16px; padding: 0; margin: 0; }
.divh5 h5{font-family:Aktiv Grotesk; font-size: 13px; padding: 0; margin: 0; }
.divh6 h6{font-family:Aktiv Grotesk; font-size: 12px; padding: 0; margin: 0; }
ul{list-style: none;}
button:focus {outline: none;}
.wxh { width: 100%; height: 100% }
.common-btn button {
    border: none;
    border-radius: 4px;
    background-color: #5d39db;
    color: #fff;
    cursor: pointer;
    height: 40px;
    width: 100px;
    line-height: 40px;
    text-align: center;
}
.common-btn button a{ color: #fff; display: block; }
.common-btn button:focus { outline: none !important; }

.sign-page {
      background: url(../img/bubble-bg.jpg) no-repeat center center fixed;

    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    height: auto;
     min-width: 100%;
  min-height: 100%;
   position: fixed;
  top: 0;
  left: 0;
  padding-top: 140px;
}

.sign-page .logo-home img{ width: 300px; display:block; margin:0 auto 0 auto; }
.sign-page .logo-home-ekal img{ width: 200px; display:block; margin:0 auto 0 auto; }

.sign-page .form-group {margin-bottom: 2px;}
.sign-page .form-control { height: 56px; border-radius: 0; border: none; }
.sign-page .common-btn button { box-shadow: #8894c14f 0 0 15px 0px;}
.sign-page .common-btn  {float: right;}

.sign-in {position: relative; }
.sign-in .common-btn button {
    height: 50px;
    width: 110px;
    position: absolute;
    right: 0;
    margin-top: -85px;
    margin-right: -40px;
}

.sign-page .divh6 h6 a{font-size: 14px; display: block; color: #4a91e3; padding-top: 6px; text-decoration: none; }

.sign-page .cust_check label{ font-size: 14px; color: #a3a4a9; font-weight: 500; }


.sign-page .common-btn button:hover { background: none;
    color: #5d39db;
    transition: 1s ease;
    box-shadow: #5d39db1f 0 0 5px 7px; }
.sign-page .common-btn button:hover a{ color:#5d39db; }

.forgot-pass  .common-btn button:hover { background: none;
    color: #5d39db;
    transition: 1s ease;
    box-shadow: #5d39db1f 0 0 5px 7px; }

.forgot-pass .common-btn button:hover a{color:#5d39db !important; }


.forgot-pass {
       background-image: url(../img/bubble-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    padding: 80px 0 150px 0;
    position: relative;
}

.forgot-pass .close img{ position: absolute;right: 0; }
.forgot-p .dots {
    font-size: 18px;
    left: 0;
    top: 0;
    color: #ccc;
    margin: 0px 0px 0 38px;
    position: absolute;
}
.dash-mid { padding-top: 50px !important;  position: relative;}

.forgot-pass .form-img img{ width: 170px; display:block; margin:0 auto 0 auto; }
.forgot-pass .forgot-p {
    background: #fff;
    padding: 30px 40px 50px 40px;
    box-shadow: #8894c121 0 0 15px 0px;
    
 }

.forgot-pass .forgot-p .divh3 h3 { text-align: center; padding-top: 30px; }
.forgot-pass .forgot-p .common-btn button { height: 40px;position: inherit; margin: 0; }
.forgot-pass .forgot-p .common-btn button a{color: #fff; display: block;}
.forgot-pass .divh4 h4 a{color: #a3a4a9; text-decoration: none;}
.forgot-pass .common-btn { float: inherit; text-align: center; }
.forgot-pass .otp ul{list-style: none; margin: 0; padding: 10px 0 0 0;}
.forgot-pass .otp ul li {display: inline-block;  width: 33px; border-bottom: 1px solid #8854b9;
    margin-right: 16px;  text-align: center;}
.forgot-pass .otp ul li span  {color: #8854b9; font-size: 16px; display: inline-block; text-align: center;}

.forgot-pass .divh6 h6 a{font-size: 14px; color: #4a91e3; display: block; margin-top: 25px; text-align: center; }
.forgot-pass .divh6 h6 a:focus {border:none; outline: none;}
.forgot-pass .otp-sec .divh4 h4{ padding-left: 0; padding-top: 20px; }
.forgot-pass .otp-sec .form-control {border:none;}


.forgot-pass .loginbtn button {
    color: #68686a;
    font-weight: bold;
    border: none;
    background: #f6f7fb;
    padding: 10px 20px;
    width: 100%;
    margin-top: 10px;
    text-align: left;
}
.forgot-pass .loginbtn button:focus {outline:none;}

.reset-btn  .divh4 h4 { padding-left: 20px; padding-top: 20px; }
.reset-btn .reset a{ color: #8e8e8e; border:none; background: none; }
.error { color: #e64346; font-size: 12px; }
.success { color: green; font-size: 12px; }


.forgot-pass-sent .reset-btn .divh4 h4 { padding-top: 10px; }

.forgot-pass-sent .sent-link .divh5 h5 { color: #999999; font-weight: 600; padding-top: 30px; }

.forgot-pass-sent .send-btn h6 a{font-size: 14px; color: #4a91e3; display: block; padding-top: 25px; text-align: center; }


.loginhform .form-control { border: none; border-bottom: 1px solid #f1f1f1; border-radius: 0; padding-left: 0; margin-left: 0; }
.loginhform textarea.form-control{ height: 100px; }
.loginhform textarea.form-control.descrip { color:#b3b3b3; border-color:#b3b3b3; }

.loginhform input { position: relative; }
.loginhform label {
    font-size: 12px;
    top: 0;
    left: 0;
    position: absolute;
    margin: 0 0 0 14px;
}

.loginhform .email { color: #8b54b9; border-color: #8b54b9; }
/*.loginhform .mob { color: #e64346; border-color: #e64346; }*/
.loginhform .mob { color: #8b54b9; border-color: #8b54b9; }
/*.loginhform .descrip { color: #68686a; border-color: #68686a; }*/
.loginhform .descrip { color: #8b54b9; border-color: #8b54b9; }

.loginhform  input::-webkit-outer-spin-button,
.loginhform  input::-webkit-inner-spin-button {}

.loginhform input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.loginhform input[type=number] {
  -moz-appearance: textfield;
}

.select2-container--default .select2-selection--single
 {border: none;
    border-bottom: 1px solid #c7cbd6; }

input.select2-search__field {
    outline: none;
}
span.select2-selection.select2-selection--single:focus { outline: none; }

.learning .divh4 h4{font-size: 15px;}

.learning .loginhform .form-control{border-radius: 0; border-color: #ccc; padding-left: 7px;}

.learning .loginhform label {
    margin: 0;
    padding-left: 30px;
    padding-top: 2px;
    cursor: pointer;
}

.dash {
    position: fixed;
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
   z-index: 1010;
    width: 100%;
}
.visible { position: relative; z-index: 10 !important; }
.dash .logo {padding: 12px 0;}
.dash .logo img{ width: 100%; max-width: 160px; }


.pratice-mode .logo { display: none }
.pratice-mode .hamburg {font-size: 18px; color: #000; font-weight: 500; padding-top: 20px;     display: inline-flex;}
.pratice-mode .hamburg span {
    padding-right: 20px;
    cursor: pointer;
    padding-left: 22px;
}

.userprofile ul {list-style: none; margin: 0; padding: 0;}
.userprofile ul li {display: inline-block; padding-right: 30px;}

.notification { border-left: 1px solid #ccc; height: 100%;
    line-height: 100%; padding-top: 18px; padding-left: 35px; position: relative; }
/*
.notification .dropdown-menu { margin: 24px 0 0 0; }
 .notification.profile-pic .dropdown-menu {margin: 15px 0 0 0 !important;}*/
.notification span {
  font-family: "Aktiv Grotesk";
    border-radius: 50%;
    background: #eb3132;
    color: #fff;
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    margin: -8px -10px 0 4px;
}
.notification i.fa.fa-bell-o {
  color: #bdbdbd;   font-size: 24px; cursor: pointer; position: relative;
}

.notification button{ background:none; cursor: pointer; border:none; }

.notification .dropdown-item {
    display: block;
    width: 100%;
    padding: 8px 10px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 12px;
}

.dropdown-notification {position: relative; display: inline-block;}
.dropdown-content {display: none; position: absolute; border-radius: 4px; background-color: #fff;
  min-width: 350px;border:1px solid #ccc; right: -44px; padding: 12px 16px;z-index: 1; }
.dropdown-notification:hover .dropdown-content {display: block; cursor: pointer;}


.dropdown-content .top-noti span {
    float: right;
    display: inline-block;
    color: #696363;
    background: none !important;
    margin: 0 0 0 0;
    padding: 9px 17px 0 0;
    width: inherit;
}
.dropdown-content .top-noti img{width: 15px; margin-right: 10px; display: inline-block !important; }
.dropdown-content .bot-noti ul{list-style: none; margin: 0; padding: 40px 0 0 0;}

.dropdown-content .bot-noti ul li {     padding-bottom: 20px; position: relative;  padding-left: 35px;}
.dropdown-content .bot-noti ul li img{ width: 22px; position: absolute; left: 0; top: 0; }
.dropdown-content .bot-noti ul li h4{ color: #a763cb; font-size: 13px; }
.dropdown-content .bot-noti ul li h5{ color: #5d5c5c; font-size: 15px; }
.dropdown-content .bot-noti ul li h6{ color: #ccc; font-size: 12px; }
.dropdown-content .bot-noti ul li span{font-size: 11px;}


.profile-pic {border-left: 1px solid #ccc; height: 100%;  padding-top: 4px; padding-left: 33px;}
.notification img { display: block; margin: 0 auto 0 auto; line-height: 100% }

.profile-pic img{display: block;  width: 46px; height: 46px;
  border-radius: 50%; margin: 0 auto 0 auto;  }

.side-nav {
   background: #fff;
    height: 100vh;
}
#page-content-wrapper { background: #fff; }
.side-nav ul{list-style: none; margin: 0; padding: 0;}
.side-nav ul li {
    padding-bottom: 20px;
    padding-left: 27px;
    padding-top: 20px;
    position: relative;

}

.perf-bdr .side-nav ul li {position: relative !important;}

.perf-bdr .side-nav ul li:first-child span{content: ''; background-color: #5d39db;
width: 6px; height: 40px;   left: 63px;  top: 0;  margin-top: 4px;  z-index: 1000;
position: absolute; }
.perf-bdr .side-nav ul li:nth-child(2) span{content: '';  background-color: #5d39db;
width: 6px; height: 40px;   left: 63px;  top: 0;  margin-top: 4px;  z-index: 1000;
position: absolute; }

.side-nav ul li:nth-child(2):before {
    content: '';
    background-color: #e2e2e2;
    width: 230px;
    height: 1px;
    left: 8px;
    top: 69px;
    position: absolute;
}
ul.fix-left-nav li h6{ display: none; color: #767676; font-size: 13px; }
ul.fix-left-nav li a h6{ display: none; color: #767676; font-size: 13px; }
.fix-left-nav { position: fixed; }
#wrapper.toggled  ul.fix-left-nav li a h6 { display: inline-block;}

.side-nav ul li a{ display: block; color: #767676; font-size: 13px; }
.side-nav ul li img { margin-right: 20px; }

.side-nav ul li:hover a{ color: #5d39db; }
.dash-mid .col-md-3 {
    padding: 0;
    /*box-shadow: #e2e1e491 -1px 11px 7px 2px;*/
}
.side-nav ul li:before.active {
  content: '';
  background-color: #5d39db;
    width: 6px;
    height: 40px;
    right: 0;
    top: 0;
    margin-top: 300px;
    z-index: 1000;
    position: absolute;
    cursor: pointer;
}
.ham { position: relative; z-index: 10000; }
.ham2 { position: absolute; right: -40px; top: 25px; cursor: pointer;  }
.ham2 .fa {font-size: 20px; color: #000; cursor: pointer;}

.side-nav .lft-side-menu.middle-menu {
    padding-top: 250px;
}
.dnone {display: none;}
#wrapper.toggled  .middle-menu {padding-top: 0px !important; }
#wrapper.toggled  .dnone {display: block;}
#wrapper.toggled  .bgblack { background: #000 !important; z-index: 10000; position: relative; }
#wrapper.toggled  .sidebar-nav { position: fixed !important; border-right: 1px solid #cccccc; }
#wrapper.toggled #sidebar-wrapper { z-index: 10000; }

#wrapper.toggled .ham .ham2 { display: block !important; opacity:10;  }
#wrapper.toggled  .perf-bdr .side-nav ul li:first-child span { display: none; }
#wrapper.toggled  .perf-bdr .side-nav ul li:nth-child(2) span { display: none; }

.disnone {font-size: 15px;
    color: #000;
    font-weight: 500;
    padding-top: 20px;  display: inline; }
#perf-text.toggled .disnone1{display:  none !important; opacity: 0; font-size: 12px;}

.dashboard-logo  img {
    width: 170px;
    padding: 7px 0;
    margin: 0 auto 0 auto;
    display: block;
}
.dash-2-btn { position: relative; }
.push-end-btn { position: absolute; right: 0; }
.push-end-btn ul li{display: block !important;  }
.bgt { background: #000; }
.logo  { display: none; }
.logo img{     width: 170px;
    padding: 10px 0;
    margin: 0 auto 0 auto;
    display: block;}

.dash-mid .col-md-2 {
    padding: 0;
    /*box-shadow: #e2e1e491 -1px 11px 7px 2px;*/
}
.side-select {background-color: #5d39db;}
.side-select  select.form-control{font-size: 13px !important;}

/*.side-select select {
       padding-left: 41px;
    padding-right: 40px;
    margin-right: 46px;
    position: relative;
}
*/

.notification .dropdown-menu { width: 200px;  margin: 0 -21px;}

.notification .dropdown-menu  a .fa { color: #5d39db; font-size: 12px; padding-right: 10px;}
.notification .dropdown-menu  a { border-bottom: 1px solid #f1f1f1; padding-bottom: 10px;  font-size: 12px;}
.side-select .slect-icon {  left: 25px;
    top: 7px; position: absolute; z-index: 100; }

.notification.profile-pic .dropdown-menu { width: 120px !important; margin: 0 -75px; }
.dash-mid .side-nav select.form-control {
    border: none;
    background-color: #5d39db;
    border-radius: 0;
    color: #fff;
    height: 50px;
    padding-left: 75px;
    cursor: pointer;
}


.side-select { position: relative;  cursor: pointer;}
.side-select  select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
.side-select select::-ms-expand {
  display: none;
}
.side-select:before {
    content: '\f107';
    position: absolute;
    border-radius: 100%;
    font-size: 20px;
    font-family: fontawesome;
    color: #fff;
    top: 11px;
    right: 48px;
}

.side-nav select option { padding-left: 40px; margin-right: 40px; }


 .dash-right-s img {
    box-shadow: #b4add47d -1px 1px 6px 3px;
    width: 100%;
    display: block;
    border-radius: 5px;
    width: 100%;
    margin: 0 auto 0 auto;
}





.dash-mid .common-btn button{ width: inherit; padding: 5px 20px; height: inherit;  }

.dash-select select.form-control.select2 {
    box-shadow: #b4add47d -1px 1px 4px 2px;
}

.dash-select  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 40px;
    height: 40px;
    border-radius: 5px;
    box-shadow: #b4add47d -1px 1px 4px 2px;
}
.dash-select span .select2-search.select2-search--dropdown {
    display: none;
}

.dash-select  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 6px;
    right: 27px;
    width: 20px;
}


.dash-select  .select2-container--default .select2-selection--single {border: none;}



 .dash-right-s .dash-mid-img {
  background-image: url(../img/dash-img2.jpg);
    background-position: center;
    background-repeat: no-repeat;
   background-attachment: scroll;
    background-size: cover;
    padding: 50px 40px;
    position: relative; border-radius: 5px;}

 .dash-right-s .dash-mid-img h1 {color: #fff; font-size: 35px; font-weight: 500; text-align: center;}
 .dash-right-s .dash-mid-img p {color: #fff; font-size: 18px; padding-top: 20px; font-weight: 500;}


.switch {
    display: block;
  margin: 12px auto;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 90px;
    height: 30px;
    padding: 3px;
    background-color: white;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background:#bdc3c7;
    -moz-border-radius: inherit;
    -webkit-border-radius: inherit;
    border-radius: inherit;
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background border;
    -moz-transition-property: opacity background border;
    -o-transition-property: opacity background border;
    transition-property: opacity background border;
}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  font-size:14px;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #000;
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
    background: #5d39db;
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
    position: absolute;
    top: 5px;
    left: 6px;
    width: 25px;
    height: 25px;
    background: white;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}
    .switch-handle:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        width: 12px;
        height: 12px;
        background: #f9f9f9;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -moz-box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
        -webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
        background-image: -webkit-linear-gradient(top, #eeeeee, white);
        background-image: -moz-linear-gradient(top, #eeeeee, white);
        background-image: -o-linear-gradient(top, #eeeeee, white);
        background-image: linear-gradient(to bottom, #eeeeee, white);
    }


.switch-input:checked ~ .switch-handle {
    background:#fff;
    left: 60px;
    -moz-box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-handle:before{
    background:#fff;
}
.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}
.dash-select select {color: red;}
 .select2-search--dropdown { position: relative; }
.select2-selection .select2-selection--single {color: red;}

.select2-search--dropdown:before {content: "\f002"; font-family: fontawesome; right: 16px; top: 10px;
    position: absolute;}
.dash-select .select2-container--default .select2-selection--single .select2-selection__rendered
{ padding-left: 22px; color:#333; }

.dash2 { padding-bottom: 50px;
    box-shadow: #e2e1e4 1px 6px 7px 2px; }


.dash2-select { padding-top: 10px; }
.dash2-select ul { list-style: none; margin: 0; padding: 0; background-color:#eeeeee;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc; }

.dash2-select ul li{ display: inline-block; padding-right: 10px; }
.dash2-select .form-control {    background: none;
    background-clip: padding-box;
    border: none;}

.clock img{ vertical-align: top; }
.clock span{color: #000;
    font-size: 25px;
    font-weight: 500;
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
    margin-top: 0px;}

.dash-2-bdr { border-bottom: 1px solid #caccd9; }


.dash2-q .divh4 h4{ color: #a97fcb; }
.dash2-q  ul { list-style: none; margin: 0; padding: 0 0 0 0; }
.dash2-q  ul li{ display: inline-block; padding-right: 20px; }
.dash2-q  ul li img{ cursor: pointer; }

.share-mod .modal-content {
    border:none;
/* box-shadow: #f1f1f1b8 -3px 1px 7px 2px;*/
 border-radius: 2px; padding: 20px 25px; }

.share-mod  .common-btn button {
    width: 100%;
    padding: 5px 20px;
    height: 45px;
    border-radius: 2px;
    line-height: inherit;}


.share-mod .form-control
    {height: 45px; font-size: 14px; color: #aaaaaa;}

.share-mod  .divh3 h3 {
    font-family: Aktiv Grotesk;
    font-size: 20px;
    padding: 0;
    margin: 0;
    color: #686868;
}

.share-mod .form-control::placeholder {
  color: #d6d6d6;}

.dash-accord .card-header {
    border: 1px solid rgba(0,0,0,.125);
       background-image: linear-gradient(to right, #021d1c, #0b6766);
    padding: 30px 20px;
    cursor: pointer;
}
.dash-accord  .card {
    border: none;
}
.dash-accord  .card-title {font-size: 16px; color: #fff !important }


.dash-accord  .accordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f068";
    float: right;
    color: #fff;
}

.dash-accord  .accordion .card-header.collapsed:after {
    content: "\f067";
    color: #fff;
}
.dash-accord  .card-header a span{font-size: 32px; font-weight: bold; color: #0fdcda; padding-right: 20px;}


.dash-accord .faq-accord  { padding: 25px 0; }
.dash-accord  .card-header {
    border:none;
       background:none;
    padding: 0 0px;
    cursor: pointer;

}

.dash-accord  .card-body p{ padding: 10px 0 0 0; margin: 0; }

.dash-accord  .card-title {font-size: 16px; color: #767676 !important ; }
.dash-accord  .card-body {

    padding: 0 0px;

}

/*.dash-accord  .accordion .card-header:after {*/
/*    font-family: 'FontAwesome';*/
/*    content: "\f107";*/
/*    float: right;*/
/*    color: #767676 !important;*/
/*    font-size: 24px;*/
/*}*/

/*.dash-accord .accordion .card-header.collapsed:after {*/
/*   content: "\f105";*/
/*     color: #767676 !important;*/
/*     font-size: 24px;*/
/*}*/


.dash-accord  .faq-accord { border-bottom: 1px solid #caccd9; }


.raio-tit {font-family:Aktiv Grotesk; font-size: 16px; border-bottom: 1px solid #caccd9; padding-bottom: 15px;}
.radio-btn ul li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #dadde6;
}

.radio-btn ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.radio-btn ul li label{
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 15px;
  padding: 10px 0px 30px 30px;
  margin: 0 auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.radio-btn ul li .katex-display {margin:0 !important;}
.radio-btn ul li .check{
  display: block;
  position: absolute;
  /*border: 1px solid #AAAAAA;*/
  border: 2px solid #5d38dd;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 17px;
  left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.radio-btn ul li:hover .check {
  border: 1px solid #000;
}

.radio-btn ul li .check::before {
      display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 4px;
    left: 4px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.radio-btn input[type=radio]:checked ~ .check {
  border: 1px solid #5d38dd;
}

.radio-btn input[type=radio]:checked ~ .check::before{
      background: #5d38dd;
}

.radio-btn input[type=radio]:checked ~ label{
  color: #5d38dd;
}




.dash-2-btn .common-btn button {
    width: inherit;
    padding: 0 15px;
    height: inherit;
    font-size: 14px;
}

.dash-2-btn .dash-hov button:hover {
    background: none;
    color: #5d39db;
    /* border: 1px solid #5d39db; */
    transition: 1s ease;
    box-shadow: #5d39db1f 0 0 5px 7px;
}
.dash-2-btn .dash-hov button:hover a{ color: #5d39db;}
.dash1 .common-btn button { width: inherit;  height: inherit;   border-radius: 4px;
    box-shadow: #b4add48c -1px 1px 6px 4px;}
.dash1 .common-btn button  a{display: block; width:inherit; padding: 5px 50px;  }


.dash1 .common-btn button:hover {
    background: none;
    color: #5d39db;
    /* border: 1px solid #5d39db; */
    transition: 1s ease;
    box-shadow: #5d39db1f 0 0 5px 7px;
}
.dash1 .common-btn button:hover a{ color: #5d39db;}


.solution button a{ width: 120px; }

.dash-2-btn  .common-btn.btn-change button { width: 130px; height: 40px; line-height: 40px;
 border-radius: 6px; background-color: #666666; padding: 0; margin-bottom: 10px;}

.share-mod2 .pop2-img img { display: block; margin: 0 auto 0 auto }
.share-mod2 .divh3 h3{ text-align: center; padding: 25px 40px; }

.share-mod2 .common-btn button { width: 120px; border-radius: 4px; }

.share-mod3 img { vertical-align: middle; }
.score-points span {font-size: 14px;  display: inline-block; vertical-align: middle; }

.share-mod3 .score-points .col-md-6 { padding-bottom: 10px; }

.panel-op {
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.5s;
    padding-top: 10px;
    height: 100%;
    z-index: 11;
}
.panel-op .panel-open {
    position: fixed;
    right: 40px;
    top: 50%;
    z-index: 10000;
    cursor: pointer;
    margin-top: 18px;
}

.right-s-pan .sidepanel {
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    height: 100%;
    box-shadow: #d4d4d438 0 0 4px 5px;
    z-index: 100000;
}

.right-s-pan .sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}


.right-s-pan .sidepanel .closebtn {
    position: absolute;
    top: 50%;
    left: -24px;
    font-size: 40px;
}

.right-s-pan .sidepanel a i.fa.fa-angle-right {
    color: #757575;
}
.dash2-q {position: relative;}

.panel-open {
    position: absolute;
    right: 40px;
    top: 50%;
    z-index: 10000;
    cursor: pointer;
    margin-top: 10px;
}
.panel-open button {  background:none; border: none; cursor: pointer; }


.panel-open button:focus {outline: none;}

.panel-open button i.fa.fa-angle-left {
    font-size: 30px;
    color: #757575;
}

.multiple-select-custom .css-1r4vtzz{border-radius:6px;}

.scroe-c  { padding-left: 30px; padding-top: 50px; }
.scroe-c img{ display: block; margin:0 auto 0 auto; width: 150px !important; box-shadow: inherit !important; }
.scroec-tit {font-size: 17px; font-weight: 600; padding-bottom: 20px;
    padding-left: 60px; }

.right-s-pan .scroe-tit img{ width: 25px !important;  box-shadow: inherit !important; display: inline-block; }
.score-num { padding-left: 45px; }
.score-num span{ display: inline-block; margin-left: 10px; }
.score-num ul {
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;
}
.score-num ul li {
    color: #fff;
    width: 40px;
    height: 40px;
     line-height: 40px;
    /*background-image: linear-gradient(#2f7a73, #42a37a);*/
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
}

.katex-display>.katex {
    text-align: left !important;
    /*display: inline-block !important;*/
}
/*.score-num ul li:nth-child(2){background-image: linear-gradient(#882c6d, #a93672);}*/
/*.score-num ul li:nth-child(10){background-image: linear-gradient(#882c6d, #a93672);}*/
/*.score-num ul li:nth-child(3){background-image: linear-gradient(#696969, #808080);}*/

.leftbar .left-side-bar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.leftbar .left-side-bar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.leftbar .left-side-bar a:hover {
  color: #f1f1f1;
}

.leftbar .left-side-bar .btnclose {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.leftbar .btnopen {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.leftbar .btnopen:hover {
  background-color: #444;
}
.leftbar #main {
  transition: margin-left .5s;
  padding: 16px;
}

/*@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
*/


.leftbar { position: relative; }

.leftbar .left-side-bar { position: absolute; width: 100%; padding-top: 0; }

.leftbar .left-side-bar ul { list-style: none; margin: 0; padding: 0; }


.leftbar .left-side-bar ul li{ width: 100%; }
.leftbar .left-side-bar ul li {  }


.side-pan-open {position: relative;}
.side-pan-open button{ width:4px; height: 10px; position: absolute; top: 50%; right: 0; background-color: #5d39db !important}

.dash-mid.perf .col-md-3 { padding-left: 15px; padding-right: 15px; }

.performance .tab {
  overflow: hidden;


}


.performance  .tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}




.performance  .tab button.active {

  border-bottom: 3px solid #8955ba;
}


.performance .tabcontent {
  display: none;
  padding: 6px 12px;
 background: #fff;
  border-top: none;
}



.performance .user {
   box-shadow: #efefef91 0px 0px 2px 1px;
    border-radius: 5px;
}
.performance .user .user-table ul { list-style: none;  margin: 0; padding: 0 0 0 8px; }
.performance .user .user-table ul li{ width: 100%; font-size: 18px; font-weight: 600;
 padding-right:2px; color: #555555; text-align: center; }
.performance .user img{ width: 100%; border-radius: 5px;   }
.performance .user .user-table ul li select {
    border: none;
    font-size: 14px;
    width: 70%;
    margin: 0 auto;
    cursor: pointer;
}
.performance .user .table th{font-size: 14px;}
.performance .user .table td {
    border: none;
    font-size: 12px;
}
.react-tabs__tab {
    margin-right: 30px !important;
}
.user img{width: 100%;}
.user-table ul{
    list-style:none;
    margin: 0px;
    padding: 0;
    text-align: center;
}
.user-table ul li h4{
    font-size: 26px;
    text-align:center;
    color:black;
    padding: 10px 0px 0px 0px;
        margin: 0px;
}
.user-table ul li h4 span{
    font-size: 15px;
    color:#848484;
    display:block;
}
.user-table ul li select{
    border:none;
    font-size:12px;
    -moz-appearance: none;
        /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
}
.user-table ul li:nth-child(2){
    margin-top:-14px !important;
}
.user-table ul li select.form-control{
    font-size: 12px;
    width: 56%;
    margin: 0 auto;
    display: block;
    background-color: transparent;
}
.user-table ul li{
    position:relative;
}
.user-table ul li:nth-child(2):before {
    content: '\f107';
    position: absolute;
    border-radius: 100%;
    font-size: 20px;
    font-family: fontawesome;
    color: #333;
    top: 3px;
    right: 80px;
    z-index: 1000;
}
.perf-date ul li select{
    /*border:none;*/
    font-size:12px;
    -moz-appearance: none;
        /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
    box-shadow: #efefef -2px 2px 9px 2px;
}
.perf-date ul li {
    width:155px;
    position: relative;
}
/*.perf-date ul li:before {*/
/*    content: '\f107';*/
/*    position: absolute;*/
/*    border-radius: 100%;*/
/*    font-size: 20px;*/
/*    font-family: fontawesome;*/
/*    color: #333;*/
/*    top: 3px;*/
/*    right: 25px;*/
/*    z-index: 1000;*/
/*}*/
.perf-date ul li select.form-control{
    /*font-size: 15px;*/
    width: 100%;
    /*margin: 0 auto;*/
    /*display: block;*/

}
.perf-bdr-sha {
    box-shadow: #b6a6ef2b -7px 4px 9px 2px;
}
.perf-bdr-sha.mrgn-0{margin: 0 0px;}
.performance  .user .table th {  border-top:none; }
.performance  .user-table th { color: #515151; font-weight: 500; }
.performance  .user-table { padding: 0 0;  }

.performance  .user-table td:nth-child(2) { color: #4a91e3; }
.performance  .user-table td:nth-child(3) { color: #4a91e3; }

.performance  .user-table { padding-top: 10px; }

.more a { display: block; padding: 20px 15px; text-align: right; }
.performance .perform-right .bdr-shad { box-shadow: #efefef91 0px 0px 2px 1px; border-radius: 8px;}
.perform .performance .col-md-3 { padding:0 10px;  }

.performance .perform-right ul { list-style: none; margin: 0; padding: 0;}

.performance .perform-right ul li{ width: 48%;  display: inline-block; }
.performance .perform-right ul li select.form-control {
    font-size: 12px;
    box-shadow: #efefef -2px 2px 9px 2px;
    height: 35px;}

.perform-right .dat {font-size: 12px;}

.performance .perform-right .divh4 h4{ padding-bottom: 25px; }

.perform-in ul{list-style: none; padding: 40px 0 0 30px; text-align: left !important;}
.perform-in ul li {font-size: 15px; color: #bbbbbb; padding-bottom: 35px; padding-right: 15px;
    width: 46%;  display: inline-block; font-weight: 600;}
.perform-in ul li span{ font-size: 22px; color: #555555; display: block; padding-top: 8px; text-align: left; }

.perform-in img{ width: 100%; }
.perform-in .col-md-4 {border-right: 1px solid #f1f1f1;}

.perf-graph img { padding-top: 20px; width: 100%;}

.perform-in .div4 h4 { padding-left: 20px !important; }


.performance .perf-date ul{list-style:none; margin: 0; padding: 0;}

.performance .perf-date ul li{display: inline-block; width: 45%; box-shadow: #efefef -2px 2px 9px 2px;}
.performance .perf-date ul li select{font-size: 12px;}
.performance .perf-date ul li input{font-size: 10px; height: 40px;}


 .perf-bdr-sha .col-md-6 { position: relative; }
 .perf-bdr-sha .col-md-6:before {content: '';
    height: 100%;
    width: 1px;
    background: #e6e6e6;
    right: 0;
    top: 30px;
    position: absolute;
}

/*.performance .perf-table {overflow-x: scroll;}*/
.performance .perf-table table th{font-weight: 600;color: #555555; font-size: 15px; text-align: center;}
.performance .perf-table table tr td{font-size: 13px;}
.performance .perf-table table tr td span{ color: #6ea1f7; }
.performance .perf-table table tr th span{font-size: 11px; display:inline-block;}
.performance .perf-table .progress { height: 4px; }
.progress-bar { background-color: #6bc99b !important; }

.performance  .tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
      color: #565656;}

.progress-bar.bar2 { background: #FFA500 !important; }
.progress-bar.bar1 { background: #32CD32 !important; }
.progress-bar.bar3 { background: #B22222 !important; }
.prog-main { position: relative; cursor: pointer;}

.prog-msg {background: #fff;  border-radius: 5px; border: 1px solid #ccc;
    padding: 12px 15px; position: absolute; box-shadow: #bbbbbb4f 0 0 9px 9px;
    width: 150px; display: none; }

.prog-main:hover .prog-msg{display: block !important; cursor: pointer;}

.prog-msg ul { list-style: none; margin:0; padding: 0; }
.prog-msg ul li{ padding-bottom: 10px; color: #000; font-weight: 600; font-size: 13px; }
.prog-msg ul li span{ display: inline-block; color: #3a3838 !important; font-weight: 500; float: right;
 font-size: 12px; padding-left: 18px; }
.prog-msg ul li:first-child span {width: 14px; height: 14px; background: #32CD32; border-radius: 50%;
    padding: 0;}
.progress:hover .prog-msg{display: block !important;}

.prog-msg1 {background: #fff;  border-radius: 5px; border: 1px solid #ccc;
    padding: 12px 15px; position: absolute; box-shadow: #bbbbbb4f 0 0 9px 9px;
    width: 150px; display: none; }

.prog-main:hover .prog-msg1{display: block !important; cursor: pointer;}

.prog-msg1 ul { list-style: none; margin:0; padding: 0; }
.prog-msg1 ul li{ padding-bottom: 10px; color: #000; font-weight: 600; font-size: 13px; }
.prog-msg1 ul li span{ display: inline-block; color: #3a3838 !important; font-weight: 500; float: right;
 font-size: 12px; padding-left: 18px; }
.prog-msg1 ul li:first-child span {width: 14px; height: 14px; background: #FFA500; border-radius: 50%;
    padding: 0;}
.progress:hover .prog-msg1{display: block !important;}

.prog-msg2 {background: #fff;  border-radius: 5px; border: 1px solid #ccc;
    padding: 12px 15px; position: absolute; box-shadow: #bbbbbb4f 0 0 9px 9px;
    width: 150px; display: none; }

.prog-main:hover .prog-msg2{display: block !important; cursor: pointer;}

.prog-msg2 ul { list-style: none; margin:0; padding: 0; }
.prog-msg2 ul li{ padding-bottom: 10px; color: #000; font-weight: 600; font-size: 13px; }
.prog-msg2 ul li span{ display: inline-block; color: #3a3838 !important; font-weight: 500; float: right;
 font-size: 12px; padding-left: 18px; }
.prog-msg2 ul li:first-child span {width: 14px; height: 14px; background: #B22222; border-radius: 50%;
    padding: 0;}
.progress:hover .prog-msg2{display: block !important;}

.performance  .tab button.active {

  border-bottom: 3px solid #8955ba;
}


.performance .tabcontent {
  display: none;
  padding: 6px 12px;

  border-top: none;
}

.performance .tab {position: relative;}
.performance .tab:before {content: ''; height: 1px; width: 550px; background: #d6d8e3;  left: 0;
    top: 55px;   position: absolute;}

.perform-tab-in .tabs {
  max-width: 100%;
}
.perform-tab-in .tabs-nav ul { margin: 0; padding: 0; text-align: left  !important; }
.perform-tab-in  .tabs-nav li {
   display: inline-block;
  width: 18% !important;
}

.perform-tab-in  a {


  color: #414040;
  display: block;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
}

.perform-tab-in  .tab-active a {
    border-bottom: 3px solid #8955ba;
    color: #8955ba;
    cursor: default;

}
.perform-tab-in  .tabs-stage {

  border-top: 0;
  clear: both;
  padding: 0 0px;
  position: relative;
  top: -1px;
  background: #fff;
}

.perform-tab-in .tabs-stage img { width: 30px; height: 30px; border-radius:50%; margin-right: 6px; }
.perform-tab-in .tabs-stage  { display: block !important; }
.perform-tab-in .tabs-stage  i.fa.fa-caret-up {
    font-size: 20px;
    color: #44c956;
    margin-right: 5px;
}

.tab2-cont.tab3-cont table th{font-size: 14px;  color: #555555; font-weight: 600;
 background: #ffffff !important; padding: 20px 0 !important; }
.tab2-cont.tab3-cont table  tr th{ padding-left: 15px !important; padding-right: 15px !important; }
.tab2-cont.tab3-cont table  tr td{ padding-left: 15px !important; padding-right: 15px !important; }

  button.applyBtn.btn.btn-sm.btn-primary {
    background: #6345c9 !important;
    border: none;
    border-radius: 0;
    padding: 5px 25px;
}

button.cancelBtn.btn.btn-sm.btn-default {
    background: no-repeat;
    border: 1px #ccc solid;
    padding: 5px 25px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
 background-image: linear-gradient(to right,#f74f9a,#ff8c83);
    border-color: transparent;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    line-height: 30px;
    text-align: center;
}
  th.month {
    font-weight: 700;
    color: #000;
}

.tabs-stage table th{border-bottom: none !important;}
.tabs-stage td {

    vertical-align: top;

    font-size: 12px;
}
.perform-tab-in .tabs-stage i.fa.fa-caret-down {  font-size: 20px;
    color: red;     margin-right: 5px;}
.bookmark .modal-dialog {max-width: 720px;}
.bookmark .modal-content { background: #f39c9d !important;  padding: 12px 10px !important;}
.bookmark p { color: #ffffff; font-size: 15px; }
.bookmark p span { color: blue; }


.perform-tab-in .tabs-stage  th{font-weight: 500; font-size: 14px;}
.tab2-cont  { background: #fafafa; padding-bottom: 40px; }
.tab2-cont tbody {border-spacing: 1em .5em; border-spacing: 1em .5em;
  padding: 0 2em 1em 0}
.tab2-cont table th { color: #7f8fae; font-size: 14px; box-shadow: none; background:#ffffff;  padding: 10px 0;
 font-weight: 500;  }
.tab2-cont table th { border: none !important; }
.tab2-cont table tbody tr{background-color:#ffffff; cursor: pointer; }
.tab2-cont table td {    color: #565656; font-size: 14px; padding: 12px 10px; margin: 8px 0;  vertical-align: middle;}
.tab2-cont table tbody td a{ color: #686868; padding:8px 30px;  border:1px solid #c2c2c4; border-radius: 4px; }
.tab2-cont table td span{ color: #bec0bf; font-size: 12px; display: block; }
.tab2-cont table {
        border-collapse: separate;
        border-spacing: 0 8px;
      }
      th {

      }

.tab2-cont tbody tr.selected1 {
    box-shadow: #b6a6efa1 -2px 2px 9px 2px;
    border:1px solid #5d39db;
}
.tab2-cont tr.selected1 td a{ background-color: #5d39db; color: #fff; border-radius: 4px; }
.tab2-cont tr.selected1 td:nth-child(2) { color: #5d39db; }


.tabs-stage tbody tr.selected {
    box-shadow: #b6a6efa1 -2px 2px 9px 2px;
    border:1px solid #5d39db;
    cursor: pointer;
}
.tabs-stage table tr {cursor: pointer;}
.tabs-stage table {
        border-collapse: separate;
        border-spacing: 0 4px;
        padding: 0 50px;
      }
      th {

      }

.tab3-cont table td span { display: inline-block !important; }
.tab2-cont.tab3-cont table td{font-size: 12px;}
/*.tab2-cont.tab3-cont table tbody tr { box-shadow: none !important }*/
.tab2-cont.tab3-cont table tbody tr {padding: 10px 10px !important}

.perf-review { background:#fafafa !important; }

.bdr-side { position: relative; }
.bdr-side .perform-in .divh4 h4 { padding-left: 30px; }

.bdr-side .col-md-3:before {content: ''; height: 100%; width: 1px; background: #e6e6e6; right: 0;
top:0; position: absolute;}


.slect select {
    background-color: #eeeeee !important;
    cursor: pointer;
}
.slect select option{ padding: 10px 0; }

.filter-pop button {border:none; background: none; cursor: pointer;}
.filter-pop button .fa{font-size: 24px; color: #1e1e1e;}
#overlay {
     position: relative;
  z-index: 10000;
   display: none;
}

#popup {
    max-width: 400px;
    width: 400px;
    padding: 20px;
    background: rgba(0,0,0,0.8);
    margin: 0px 0 0 auto;
    position: absolute;
    right: 0;
}

#close {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    color: #fff;
    width: 30px;
    height: 30px;
    z-index: 10000;
}

.popin h3{color: #fff; font-size: 17px;}
.popin h3 span{  margin-left: 4px; }
.popin h3 span .fa {
    font-size: 12px;
    text-align: center;
    border: 1px solid #fff;
    vertical-align: middle;
    padding: 3px 5px;
}
.popin .custom-control label {
    color: #cecece;
    cursor: pointer;
    font-size: 14px;
}
.popin .goal ul { list-style: none; margin: 0; padding: 0 0 0 0; }
.popin .goal ul li{ display: inline-block; margin-right: 10px; width: 100px; height: 40px; line-height: 40px; background: #fff; border-radius: 5px; }
.popin .goal ul li a{ display: block; color: #000;     font-size: 12px; text-align: center;  }
.popin .goal ul li a:hover { background: #8954ba; color:#fff;  border-radius: 5px; }
.popin .custom-control-label::before {  background: none !important; border: 1px solid #c3c3c3; }
.rangeslide .slidecontainer {
  width: 100%;
}
.custom-control-label::after {
    left: -20px !important;
}
.custom-control-label::before {
    left: -20px !important;
}
/*
.rangeslide .slider {
border:3px solid #5c504d;
  -webkit-appearance: none;
 width: 100%;
    height: 10px;
    background: #8553b4;
    outline: none;
    -webkit-transition: .2s;
    border-radius: 10px;
}



.rangeslide .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #7439aa;
  cursor: pointer;
  border-radius:50%;
}

.rangeslide .slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #7439aa;
  cursor: pointer;
   border-radius:50%;
}*/
.rang-btn button {  background: #8954ba; border:none; color: #fff;
 border-radius: 50%; width: 60px; height: 60px; text-align: center; padding: 0;
  text-transform: uppercase; margin-top: 20px; }
.rang-btn button:focus {outline: none;}


.rangeslide .range-slide {
  margin: 0 0;
}
.rangeslide .min-value,
.rangeslide .max-value {
  float: left;
  width: 10vw;
  color: #6c6d70;
  text-align: right;
  font-weight: bold;
  opacity: 0;
}
.rangeslide .max-value {
  text-align: left;
}
.rangeslide .current-value {
    position: absolute;
    top: -2.5em;
    left: 50%;
    width: 60px;
    height: 27px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    background: #8241be;
    border-radius: 7px;
    margin-left: -19px;
    margin-top: -4px;
}

.rangeslide .current-value:before {
      content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    background: #8241be;
    margin-left: -5px;
    z-index: -1;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 22px;
    border-radius: 10px 10px 0px 10px;
    z-index: 100;
}

.rangeslide .range {
  position: relative;
  float: left;
  width: 100%;

}
.rangeslide input[type=range] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: 6px;
  padding: 0;
  border-radius: 4px;
  background: #8241be;
  box-sizing: content-box;
    border: 4px solid #5c504d;
    border-radius: 10px;
}
.rangeslide input[type=range]:focus {
  outline: none;
}
.rangeslide input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;

  border-radius: 25px;
  background: #8954ba;

}
.select2-container .select2-choice {
    padding: 5px 10px;
    height: 100px;
    width: 132px;
    font-size: 1.2em;
}

.select2-dropdown.select2-dropdown--below{
    color: #565656;  border:none;
}

.select2-container--default .select2-results__option { padding-left: 20px; border:none;font-size: 14px; }
.select2-container--default .select2-results__option[aria-selected=true] { background: #f3f3f3;
    color: #565656; }

.select2-container--default .select2-results__option:hover { background: #f3f3f3;  color: #565656;}

.select2-dropdown.select2-dropdown--below.active {  background: red; }

#wrapper.toggled .sidebar-nav { position: relative; }

#wrapper.toggled .sidebar-nav:before {
    content: '';
    width: 1px;
    height: 60px;
    background-color: #ffffff;
    position: absolute;
    right: -1px;
    top: 0;
}
@media screen and (max-width: 1024px) {



    .ipad-responsive .col-md-3 {
        max-width: 41.666667%;
        /*max-width: 50%;*/
        flex: 0 0 60%;
    }
     .ipad-responsive .col-md-2.offset-5 {
       margin-left: 5%;
    }
     .ipad-responsive .col-md-2.c-4 {
         /*flex: 0 0 25%;*/
         /*max-width: 25%;*/
         flex: 0 0 33.333333%;
    max-width: 33.333333%;
     }
    .clock span {
    vertical-align: inherit !important;
}
     .clock img {
        vertical-align: top;
        width: 20px;
    }
     .clock span h1 {
        font-size: 20px;
    }
    .good-msg{
        width: auto !important;
    }
/*.perm-graph .graph-large{*/
/*    width:134px !important;*/
/*}*/
.perform-graph .col-md-3:nth-child(2){
                flex: 0 0 33.333333%;
    max-width: 33.333333%;
    }
.perform-graph .col-md-6 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.perform-in.perm-graph ul { padding: 53px 0 0 90px !important; }
.perform-in.perm-graph ul li {
    font-size: 9px !important;
    color: #696565;
    width: inherit !important;
    padding-bottom: 10px;
    padding-right: 0;
    font-weight: 100;
    display:block;
}
.col-graph svg{max-width: 50px;}
.g-center{text-align: center; position: relative;}
.g-center h5{
    position: absolute;
    right: 0!important;
    margin-left: 3px!important;
    top: 41px !important;
    color: black;
    font-size: 11px !important;
}
.tabcon .perf-bdr-sha .col-md-2{
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.tabcon .perf-bdr-sha .offset-6{
    margin-left: 33.333333%;
}
.perf-date ul li{
    width: 100%!important;
}
.perm-sove-ac ul li{
    padding-bottom: 5px!important;
     width:100%;
}
.perm-sove-ac ul li span {
    padding-top: 0!important;
}
.perf-graph.graphnew .result-tit {
    font-size: 10px !important;
    font-weight: 600;
    color: #6d6969;
    padding-top: 15px;
    text-align: center;
}
.graphnew .graphs .result-top li{
    font-size: 10px!important;
}
.graphnew .graphs ul li{
    padding-right: 11px!important;
}
.graphnew .graphs .result-top ul li:nth-child(1):before{
    top:4px!important;
}
.graphnew .graphs .result-top ul li:nth-child(2):before{
    top:4px!important;
}
.graphnew .graphs .result-top ul li:nth-child(3):before{
    top:4px!important;
}
.perf-graph.graphnew .result-tit span {
    font-size: 10px !important;
    font-weight: 400;
    color: #8e8a8a;
    display: block;
    text-align: center;
}
.dash2-q .divh4 h4 {
    color: #a97fcb;
    font-size: 12px;
}
.dash2-q ul li img {
    cursor: pointer;
    width: 16px;
    vertical-align: top;
}
.dash2-q ul li {
    display: inline-block;
    padding-right: 12px;

}
.radio-btn ul li { width:50%;}
.dash2-q .offset-4{
  margin-left: 16.666667% !important;
}
.dash2-q ul li:last-child img{ width: 12px;}
.pratice-mode .hamburg {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    padding-top: 20px;
    display: inline-flex;
}
.notification {    padding-top: 14px;
    padding-left: 24px;}
    .profile-pic {
    border-left: 1px solid #ccc;
    height: 100%;
    padding-top: 3px;
    padding-left: 15px;
}
.react-confirm-alert-button-group  button{
    background:#5d39db !important;
}
    .profile-pic img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 auto 0 auto;
}
.notification i.fa.fa-bell-o {
    color: #bdbdbd;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}
    .notification span {
    font-family: "Aktiv Grotesk";
    border-radius: 50%;
    background: #eb3132;
    color: #fff;
    font-size: 9px;
    width: 14px;
    height: 14px;
    line-height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    margin: -6px -6px 0 4px;
}
.select2-container { width: 100% !important; }
.sign-page .col-md-5 {max-width: 66.666667%;  }
.sign-page .col-md-12 {padding-bottom: 10px; }
.sign-page .common-btn {float: inherit;}
.sign-page .sign-in .common-btn button {
    /*position: inherit;  margin: 25px auto 0 auto;  display: block;*/
    margin-top: -100px;
}
.dash-select .col-md-6 { width: 100% !important; }

.forgot-pass  .col-md-5 {max-width: 66.666667%;  }

.forgot-pass .otp ul { text-align: center; }


.dash-2-btn .col-md-7  {max-width:70% !important;     flex: 0 0 70% !important;}
.dash-2-btn .col-md-7.offset-3 { margin-left: 10.666667% !important; }
.ham2 {
    position: absolute;
    right: -40px;
    top: 10px;
    cursor: pointer;}

    .perf-table.pt-3 {
    overflow: scroll;}

.perfresponse   .col-md-3 {max-width: 50% !important; flex: 0 0 50% !important;}
 .perfresponse  .col-md-3.offset-5{margin-left: 5%;}

      .review { overflow: scroll; }
      .performance .tab button {font-size: 12px;}

      .tabs-stage table {
    border-collapse: separate;
    border-spacing: 0 4px;
    padding: 0 0;}
    .weekly-tab1  { overflow: scroll; }

 .perform-in ul {padding: 40px 0 0 15px;}
    .perform-in ul li {
    font-size: 12px;
    color: #bbbbbb;
    padding-bottom: 20px;
    padding-right: 4px;
    width: 46%;
    display: inline-block;
    font-weight: 600;}

    .perform-in ul li span {
    font-size: 12px;}
    .bdr-side .perform-in .divh4 h4 {
    padding-left: 12px;
    font-size: 12px;
}

.perf-graph .divh4 h4 {
    font-size: 12px;
    padding: 0;
    margin: 0;}
.forgot-pass {background: #f4f5f9; position: inherit; padding-bottom: 50px;}
.dash-right-s .col-md-8 { max-width: 100%; flex: 0 0 100%; }
 .dash-select .col-md-6 {max-width: 100%; flex: 0 0 100%; margin-bottom: 30px;}
}
@media only screen and (max-width: 1024px) and (min-width: 1020px)  {
.dash2-q ul li {
    display: inline-block;
    padding-right: 12px;
    width: 50%;
}
    .bdr-side .col-md-3:nth-child(2):before {
        content: '';
        height: 85%;
        width: 1px;
        background: #e6e6e6;
        right: 0;
        top: 0;
        position: absolute;
    }
    .col-md-6.perf-graph.graphnew {
    padding-bottom: 66px;
}

    .graphnew { position: relative; }

    .graph-results { display: none; }
    .perform-in.perm-graph ul { display: none; }
    .graph-result-ab .graph-results  { display: block!important; }

    .graph-result-ab {position: absolute;
    left: -210px;
    bottom: 10px;
    margin-right: 60px; }



}
@media only screen and (max-width: 1024px) and (min-width: 990px)  {

  .col-md-5.res-size { flex: 0 0 50%;
    max-width: 50%; }
}

@media screen and (max-width: 850px) {
    .perf-bdr-sha.shdw-off{
        box-shadow: none;
    }
    .perf-bdr-sha.shdw-off .col-md-6{
        box-shadow: #b6a6ef2b -7px 4px 9px 2px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
    .notification {
        padding-left: 13px;
    }
    .text-center.text-none{
        text-align: left!important;
    }
    .ipad-responsive .col-md-3 {
        max-width: 41.666667%;
        /*max-width: 50%;*/
        flex: 0 0 60%;
    }
     .ipad-responsive .col-md-2.offset-5 {
       margin-left: 5%;
    }
     .ipad-responsive .col-md-2.c-4 {
         /*flex: 0 0 25%;*/
         /*max-width: 25%;*/
         flex: 0 0 33.333333%;
    max-width: 33.333333%;
     }
     .clock img {
        vertical-align: top;
        width: 20px;
    }
     .clock span h1 {
        font-size: 25px;
    }
    /* .text-center.text-none.solution {*/
    /*    text-align: center;*/
    /*}*/
/*.perm-graph .graph-large {*/
/*        width: 65px !important;*/
/*    }*/

    .perform-in.perm-graph ul {
        padding: 55px 0 0 1px !important;
    }

    .perform-in.perm-graph ul li {
        font-size: 9px !important;
        color: #696565;
        width: inherit !important;
        padding-bottom: 10px;
        padding-right: 0;
        font-weight: 100;
        display: block;
    }

    .col-graph svg {
        max-width: 45px;
    }
    .perform-graph .col-md-3{
            flex: 0 0 50%!important;
            max-width: 50%!important;
    }
    .perform-graph .col-md-6{
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .perform-graph-table .col-md-6{
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .perf-bdr-sha.mrgn-0 .divh4 h4 {
    padding-top: 20px;
}
    /*.g-center h5 {*/
    /*    position: absolute;*/
    /*    left: 0px !important;*/
    /*    margin-left: 47px!important;*/
    /*    top: 41px !important;*/
    /*    color: black;*/
    /*    font-size: 10px !important;*/
    /*}*/

    .perf-graph.graphnew .result-tit {
        font-size: 10px !important;
        font-weight: 600;
        color: #6d6969;
        padding-top: 15px;
        text-align: center;
    }
    .graph-results ul li {
        font-size: 10px !important;
    }

    .perf-bdr-sha .col-md-2.offset-6 {
        margin-left: 33.333333%;
    }

    .user-table ul li h4 {
        font-size: 18px !important;
    }

    .user-table ul li h4 span {
        font-size: 11px !important;
    }
    .perm-sove-ac ul li {
        font-size: 11px !important;
    }
 .perm-sove-ac ul li span {
     font-size: 16px !important;
 }

.perf-bdr-sha .divh3 h3 {
    font-size: 16px !important;
}
.perf-bdr-sha .perf-table .table thead th {
    font-size: 12px !important;
}
.perf-table {
    overflow: scroll;
}
}
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2){
    .bdr-side .col-md-3:nth-child(2):before {
    content: '';
    height: 85%;
    width: 1px;
    background: #e6e6e6;
    right: 0;
    top: 0;
    position: absolute;
}
    .graph-results {
    display: none;
}
    .perm-sove-ac ul li{
        width: 100%;
    }
    .perform-in.perm-graph ul {
    display: none;
}
    .graph-result-ab {
    position: absolute;
    left: -210px;
    bottom: 10px;
    margin-right: 60px;
}
    .graph-result-ab .graph-results {
    display: block!important;
}
    .col-md-6.perf-graph.graphnew {
    padding-bottom: 66px;
}
    .perform-graph-table .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .perf-bdr-sha.shdw-off .col-md-6 {
        box-shadow: #b6a6ef2b -7px 4px 9px 2px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
    .perf-bdr-sha.shdw-off {
        box-shadow: none;
    }
/*    .perf-table .table thead th {*/
/*    font-size: 15px;*/
/*    color: red;*/
/*}*/
}

/*@media only screen*/
/*  and (min-device-width: 1024px)*/
/*  and (max-device-width: 1366px)*/
/*  and (-webkit-min-device-pixel-ratio: 2)*/
/*  and (orientation: portrait){*/
/*    .perform-graph-table .col-md-6 {*/
/*        flex-basis: 0;*/
/*        flex-grow: 1;*/
/*        max-width: 100%;*/
/*    }*/
/*    .perf-bdr-sha.shdw-off .col-md-6 {*/
/*        box-shadow: #b6a6ef2b -7px 4px 9px 2px;*/
/*        padding-top: 10px;*/
/*        padding-bottom: 10px;*/
/*        margin-bottom: 25px;*/
/*    }*/
/*    .perf-bdr-sha.shdw-off {*/
/*        box-shadow: none;*/
/*    }*/
/*}*/
/*@media only screen*/
/*  and (min-device-width: 768px)*/
/*  and (max-device-width: 1024px)*/
/*  and (-webkit-min-device-pixel-ratio: 1)*/
/*  and (orientation: landscape){*/

/*    .perform-graph-table .col-md-6 {*/
/*        flex-basis: 0;*/
/*        flex-grow: 1;*/
/*        max-width: 100%;*/
/*    }*/
/*    .perf-bdr-sha.shdw-off .col-md-6 {*/
/*        box-shadow: #b6a6ef2b -7px 4px 9px 2px;*/
/*        padding-top: 10px;*/
/*        padding-bottom: 10px;*/
/*        margin-bottom: 25px;*/
/*    }*/
/*    .perf-bdr-sha.shdw-off {*/
/*        box-shadow: none;*/
/*    }*/
/*}*/
@media screen and (max-width: 768px) {
    .ipad-responsive .col-md-3 {
        max-width: 41.666667%;
        /*max-width: 50%;*/
        flex: 0 0 60%;
    }
     .ipad-responsive .col-md-2.offset-5 {
       margin-left: 5%;
    }
     .ipad-responsive .col-md-2.c-4 {
         /*flex: 0 0 25%;*/
         /*max-width: 25%;*/
         flex: 0 0 33.333333%;
    max-width: 33.333333%;
     }
}

@media screen and (max-width: 767px) {

.dash .logo img { width: 300px; display: block; margin:  0 auto 0 auto; padding-top: 20px 0;}
.forgot-pass .forgot-p {
    padding: 30px 20px 50px 20px;
    height: 100vh;
    box-shadow:none;
}
.forgot-pass {background: #f4f5f9; position: inherit; padding-bottom: 0px; height:100%;padding-top: 0px;width:100%;padding-left: 0px;padding-right: 0px;background-size: cover;}



}
/*@media only screen*/
/*  and (min-device-width: 1024px)*/
/*  and (max-device-width: 1366px)*/
/*  and (-webkit-min-device-pixel-ratio: 2)*/
/*  and (orientation: landscape){*/
/*        .perf-table .table thead th {*/
/*        font-size: 15px;*/
/*        color: green;*/
/*    }*/
/*    .perform-graph-table .col-md-6 {*/
/*        flex-basis: 0;*/
/*        flex-grow: 1;*/
/*        max-width: 100%;*/
/*    }*/
/*}*/
.centerxx .CircularProgressbar{
    width: 100% !important;
    /*vertical-align: middle !important;*/
    height: 68px !important;
}

.centerxx .donutchart{
    width: 100% !important;
    /*vertical-align: middle !important;*/
    height: 200px !important;
}

.dash-right-s .dash-mid-img{
  opacity: 1;
}

.new .form-group {
  display: block;
  margin-bottom: 15px;
}

.new  .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.new  .form-group label {
  position: relative;
  cursor: pointer;
      font-size: 14px;
}

.new  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #969696;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
}
.new  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
       top: 5px;
    left: 8px;
    width: 5px;
    height: 11px;
    border: solid #5d39db;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.popfive .common-btn button {
    width: 150px !important;
    padding: 5px 20px;
    height: 45px;
    border-radius: 2px;
    line-height: inherit;
    margin-left: 30px;
}
.pop_verified .common-btn button {
    width: 230px !important;
    padding: 5px 20px;
    height: 45px;
    border-radius: 2px;
    line-height: inherit;
    margin: 0 auto;
    display: block;
}
p.popup-text-color {
    text-align: center;
}
@media (min-width: 576px)
{
.popfive .modal-dialog {
    max-width: 360px;
    margin: 1.75rem auto;
}
.pop_verified .modal-dialog {
    max-width: 360px;
    margin: 1.75rem auto;
}
}
.share-mod .modal.show .modal-dialog {
    transform: translate(0,0);
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
@media (min-width: 576px){
.share-mod .modal-dialog {
    max-width: 500px;
    margin: 0 auto;
}}
.perm-sove-ac ul{ list-style: none; margin: 0; padding: 10px 0 0 0; text-align: center; }
.perm-sove-ac ul li{ display: inline-block; font-size: 14px;
    font-weight: 400;
    color: #a09c9c; padding: 0 20px;padding-bottom: 10px;  }
.perm-sove-ac ul li span{    font-size: 20px;
    font-weight: 500;
    color: #313030; display: block; padding-top: 5px;}
.perm-sove-ac ul li:hover{
    color: #a09c9c !important;
}
.solutons-p { border-bottom: 1px solid #dadde6; padding-bottom: 20px}


.solutons-p .radio-btn ul li{ border:none; }
.youans {
     background-color: #bbe5cd;
    border-radius: 40px;
    color: #000;
    font-weight: 600;
    padding: 0;
    width: 150px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 27px 0 0 auto;
    cursor: pointer;
         }
.youans.correctans { background-color: #fac0bf;}
.youans.skips { background-color: #adaca8;}
.good-msg { background-color: #e9f7e8;
    border-radius: 5px;
    width: 350px;
    color: #20b41e;
    font-weight: 600; padding:
     10px 20px;
     float: right;
     
 }
.good-msg .fa {color: #20b41e; }
.good-msg.wrong-msg { background-color:#fce8e9; color: #e11f1f; }
.good-msg.wrong-msg .fa{color: #e11f1f; }
.good-msg.skip-msg { background-color:#adaca8; color: #000; }
.good-msg.skip-msg .fa{color: #000; }
.new .form-group {
  display: block;
  margin-bottom: 15px;
}

.new  .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.new  .form-group label {
  position: relative;
  cursor: pointer;
      font-size: 14px;
}

.new  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #969696;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
}
.new  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
       top: 5px;
    left: 8px;
    width: 5px;
    height: 11px;
    border: solid #5d39db;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.popfive .common-btn button {
    width: 150px !important;
    padding: 5px 20px;
    height: 45px;
    border-radius: 2px;
    line-height: inherit;
    margin-left: 30px;
}

.solutons-p .radio-btn input[type=radio]:checked ~ .check {
    border: 1px solid #00958a;
}

.solutons-p .radio-btn input[type=radio]:checked ~ .check::before {
    background: #00958a;
}
.perf-gph .col-md-6:before {content:''; background: none; border:none !important;}


.g-center{position: relative;}
.g-center h5{position: absolute;
        left: 0px;
    margin-left: 43px;
    top: 57px;
    color: black;
    font-size: 15px;}
.graphnew .graphs ul{ list-style: none; padding: 0; margin: 0; }
.graphnew .graphs ul li{display: inline-block; padding-right: 18px; text-align: center}
.perf-graph.graphnew .result-graph img{ width: 70px; margin: 0 auto; display: block; }
.col-graph svg{width: 80px !important;}
.perf-graph.graphnew .result-tit {
    position: relative;
}
.col-graph .item{
    text-align: center;
}
.perf-graph.graphnew .result-tit p{font-size: 14px; font-weight: 600; color: #6d6969;
 padding-top: 15px;  white-space: nowrap;
  /*width: 100px;*/
  overflow: hidden;
  text-overflow: ellipsis;  text-align: center; }
.perf-graph.graphnew .result-tit h6 {
    font-size: 12px;
    color: #fff;
    background: #5d39dbd9;
    display: none;
    position: absolute;
    top: -10px;
    left: 10px;
    display: none;
    padding: 5px 5px;
}
.perf-graph.graphnew .result-tit:hover h6 {
    display: block !important;
    cursor: pointer;
}

.perf-graph.graphnew .result-tit span {font-size: 12px; font-weight: 400;  color: #8e8a8a;
 display:block; text-align: center;}
.perform-in.perm-graph ul { padding: 75px 0 0 55px; }
.perform-in.perm-graph ul li {
    font-size: 11px;
    color: #696565;
    width: inherit !important;
    padding-bottom: 10px;
    padding-right: 0;
    font-weight: 100;
    display:block;
}
.perm-graph .col-md-6:before {
    content: '';
    height: 100%;
    width: 1px;
    background: none !important;
    right: 0;
    top: 30px;
    position: absolute;
}
.graphnew .graphs .result-top ul {list-style: none; padding: 0 0px 0 12px; text-align: center;}
.graphnew .graphs .result-top li{font-size: 12px; text-align: center;}
.graphnew .graphs .result-top ul li:nth-child(1) {position: relative;}
.graphnew .graphs .result-top ul li:nth-child(2) {position: relative;}
.graphnew .graphs .result-top ul li:nth-child(3) {position: relative;}
.graphnew .graphs .result-top  ul li:nth-child(1):before {content: '';
width: 8px; height: 8px; background-color: #bcd43e;
border-radius:50%; position: absolute; left: -10px;
    top: 5px;}
.graphnew .graphs .result-top  ul li:nth-child(2):before {content: '';
width: 8px; height: 8px; background-color: #f7d478;
border-radius:50%; position: absolute; left: -10px;
    top: 5px;}
.graphnew .graphs .result-top  ul li:nth-child(3):before {content: '';
width: 8px; height: 8px; background-color: #fc7fab;
border-radius:50%; position: absolute; left: -10px;
    top: 5px;}

.perform-in.perm-graph ul li span{font-size: 10px; padding-top: 0px;}
.perform-in.perm-graph ul li:nth-child(1) {position: relative;}
.perform-in.perm-graph ul li:nth-child(2) {position: relative;}
.perform-in.perm-graph ul li:nth-child(3) {position: relative;}

.perm-graph .graph-large{
    width:195px;
}
.graphnew .graphs .result-top li {
    color:#4e4b4b!important;
}
.perform-in.perm-graph ul li:nth-child(1):before {content: '';
width: 8px; height: 8px; background-color: #bcd43e;
border-radius:50%; position: absolute; left: -13px;
    top: 5px;}
    .perform-in.perm-graph ul li:nth-child(2):before {content: '';
width: 8px; height: 8px; background-color: #f7d478;
border-radius:50%; position: absolute; left: -13px;
    top: 5px;}
    .perform-in.perm-graph ul li:nth-child(3):before {content: '';
width: 8px; height: 8px; background-color: #fc7fab;
border-radius:50%; position: absolute; left: -13px;
    top: 5px;}
.graph-results ul  {list-style: none; padding: 20px 0 0 0; margin:0; text-align: center;}
.graph-results ul li{display: inline-block; color: #9e9e9e;
 font-size: 14px; padding-right: 25px;}

.graph-results ul li:nth-child(1) {position: relative;}
 .graph-results ul li:nth-child(2) {position: relative;}
.graph-results ul li:nth-child(3) {position: relative;}

.sticky {
position: fixed !important;
    top: 57px;
    width: 100%;
    z-index: 100;
    background: #fff;
}

.graph-results ul li:nth-child(1):before {content: '';
width: 8px; height: 8px; background-color: #bcd43e;
border-radius:50%; position: absolute; left: -13px;
    top: 5px; }
.graph-results ul li:nth-child(2):before {content: '';
width: 8px; height: 8px; background-color: #f7d478;
border-radius:50%; position: absolute; left: -13px;
    top: 5px; }
.graph-results ul li:nth-child(3):before {content: '';
width: 8px; height: 8px; background-color: #fc7fab;
border-radius:50%; position: absolute; left: -18px;
    top: 5px; }


@media (min-width: 576px)
{
.popfive .modal-dialog {
    max-width: 360px;
    margin: 1.75rem auto;
}
}

.bluenew { background: #ff0; }

/*#chartdiv {
  width: 100%;
  height: 500px;
}
*/
.react-tabs__tab {
   padding: 6px 12px 6px 0px !important;
   color:  #4e4b4b!important;
    border: none!important;
    /*transition: 2s cubic-bezier(0.4, 0, 1, 1);*/
}

.react-tabs__tab--selected {
    background: #fff ;
    border-color: #aaa;
    color: #000 !important;
    border-radius: 5px 5px 0 0;
    border: none !important;
    border-bottom: 4px solid #5d39db !important;
}
.react-tabs ul.react-tabs__tab-list{
    position: relative;
}
.react-tabs ul.react-tabs__tab-list:before {
    content: '';
    height: 1px;
    width: 330px;
    /*width: 530px;*/
    background: #d6d8e3;
    left: 0;
    top: 41px;
    position: absolute;
}
.perf-table .table thead th{
    font-size: 15px;
}
.react-tabs ul li {
    transition: .9s ease-in;
}
.react-tabs ul.react-tabs__tab-list li:hover {
    color: #5d39db !important;
}
.react-tabs__tab-list{
    border:none!important;
}
.perf-bdr-sha.mrgn-0 .divh3 h3{
    color:  #4e4b4b!important;
}
.perf-bdr-sha.mrgn-0 .divh4 h4{
    color:  #4e4b4b!important;
}
/*===== The CSS =====*/
.graphnew .progress { background: none; }
.progress{
    width: 70px;
    height: 100px;
}
tbody .progress{
    width: 70px;
    height: 10px;
}
.progress .track, .progress .fill{
    fill: rgba(0, 0, 0, 0);
    stroke-width: 3;
    transform: rotate(90deg)translate(0px, -80px);
}
.progress .track{
    stroke: #ff9688;
}
.progress .fill {
    stroke: rgb(255, 255, 255);
    stroke-dasharray: 219.99078369140625;
    stroke-dashoffset: -219.99078369140625;
    transition: stroke-dashoffset 1s;
}
.progress.blue .fill {
    stroke: #89a7ff;
}
.progress.green .fill {
    stroke: rgb(186, 223, 172);
}
.progress .value, .progress .text {
    font-family: 'Open Sans';
        fill: rgb(88 88 88);
    text-anchor: middle;
}
.progress .text {
    font-size: 12px;
}
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
        cursor: default;
}
.hidethisdiv{
    visibility: hidden;
}

.react-confirm-alert-button-group > button{
    background: #5d39db!important;
}

textarea.form-control {
    display: block;
    border: 1px solid #aba8a8 !important;
     height: 75px !important;
}
.graph-large p a{
   font-size: 12px;
    text-decoration: none !important;
    display: block;
    padding: 8px 0;
    border-radius: 40px;
    color: #fff;
    background-color: #5d39db;
    text-align: center;
    width: 150px;
    margin-top: 10px;
}
.col-graph p a{
   font-size: 12px;
    text-decoration: none !important;
    display: block;
    padding: 8px 0;
    border-radius: 40px;
    color: #fff;
    background-color: #5d39db;
    text-align: center;
    width: 250px;
    margin-top: 10px;
}
.background-blur #myModal2  {
    background: #ffffffcc;
}
.background-blur #myModal {
    background: #ffffffcc;
}
.background-blur #myModal5 {
    background: #ffffffcc;
}
.background-blur #myModal4 {
    background: #ffffffcc;
}
.cust_check.remember {
    padding-top: 2px;
}
.popfive .popup-text-color{
    color: #8871da !important;
}

.wxh .dropdown-notification.notification{
    display: none;
}
.side-nav ul li:nth-child(2):before{
    background: none !important;
}
.graphs .owl-dots {
    /*display: none;*/
}
.graphs .owl-nav button.owl-prev { width: 40px; height: 40px; background: #f1f1f1!important; border-radius: 50%;}
.graphs .owl-nav button.owl-next { width: 40px; height: 40px; background: #f1f1f1!important; border-radius: 50%;}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #5d39db !important;
    color: #FFF;
    text-decoration: none;
}
.graphs .owl-theme .owl-nav {
    margin-top: 0!important;
    display: none;
}
.common-label-horizontal{
    display:none;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #6e7cf7!important;
}
.graph-result-ab .graph-results { display: none; }

.tab2-cont table tbody td button {
    color: #686868;
    padding: 8px 30px;
    border: 1px solid #c2c2c4;
    border-radius: 4px;
}
button.css-1r4vtzz {
    width: 100%;
    box-shadow: none;
}
button.css-48ayfv{
    width: 100%;
}

.multiple-select-custom{
    position: relative;
    border: 1px #d6d2d2 solid;
    border-radius: 5px;
    box-shadow: none;
}


.multiple-select-custom .css-1g6gooi {
    min-width: 318px !important;
}
.user-pro { display:none;}
.mob-side-nav {display:none;}
.mob-graph { display:none;}
.pratice-mode .hamburg { display:block;}
.notification.profile-pic {  display:block}
.ques-time { display:none;}
.notification.profile-pic.profile-mob {display:none; }
.cust_check {    padding-left: 18px;}
.custom-control { padding-left:0;}
.learning .loginhform label {padding-left:0;}

.mob-design { display:none;}
.react-tabs ul.react-tabs__tab-list li:last-child {display:none;}


 .head-icon-test h6{display:none;}


@media screen and (max-width: 767px){
.dash.pratice-mode .col-md-3 {max-width:80%;}
.dash .col-md-2.dash-mob {max-width: 80%;}
.sign-page {padding-top: 50px;}
.sign-page .logo-home img { width: 80%;  display: block;   margin: 0 auto 0 auto;}
.sign-page .col-md-5{ max-width:100% !important;}
.sign-in .common-btn button {margin-right: 0px;}
.sign-page .col-md-4 { max-width:33.333%;}
/*.forgot-pass .col-md-5 { max-width:100%;}*/
.forgot-pass  .col-md-5 {max-width: 100%  ;padding-left: 0px ;padding-right: 0px   }

.forgot-pass .otp ul li {margin-right: 12px;}
.forgot-pass .forgot-p {padding: 30px 20px 50px 20px;}
.pro-mob { margin-top:5px; margin-left:10px;}
.pro-mob .profile-pic img{ width:42px; height:42px;}
.pro-mob .notification.profile-pic .dropdown-menu {width: 120px !important;  margin: 0 -10px;}
.notification.profile-pic.profile-mob {border:none; display:block; }
.pratice-mode .hamburg { display:none;}
.notification.profile-pic { display:none;}
.clock.text-right { display:none;}
#wrapper.toggled {padding-left: 0px !important;}
#sidebar-wrapper {left:0 !important;}
.logo { display:block !important;}
.dash .col-md-2.offset-8 { display:none;}
.dash-mid {padding-top: 50px !important;  position: relative;}
.mob-side-nav { background:#fff; display:block; position: fixed; margin: 0 auto; bottom: 0; left: 0; right: 0; z-index:100;}
.mob-side-nav  .col{ padding:0 !important;}
.mob-side-nav ul { text-align: center; padding: 20px 0 15px 0;  margin: 0 0 0 0;  box-shadow: #f1f1f15e -1px -5px 4px 3px;}
.mob-side-nav ul li{ display:inline-block; padding:0 35px; position:relative;}
.dash2-q .mob-side-nav ul li{ display:inline-block; padding:0 25px; position:relative;}
.mob-side-nav ul li a{ color:#333; font-weight:600; font-size:16px;}
.mob-side-nav ul li a:hover { color:#462e86;}
.mob-side-nav ul li:hover:before {content:'';  width:100px; height:3px; background:#462e86; left:50%; top:70px; margin-left:-45px; position:absolute;}
.mob-side-nav ul li a img{ display:block; margin: 0 auto; padding-bottom:10px;}
.react-tabs { -webkit-tap-highlight-color: transparent;}
.tabcon .perf-bdr-sha {display:none;}
.mob-graph { display:block; text-align: center;}
.dash2-q .divh4 h4 {color: #a97fcb; font-size: 18px;}
.ipad-responsive .col-md-3 {  max-width: 100%;  /* max-width: 50%; */   flex: 0 0 100%;}
.dash2-q ul { padding-top:10px; padding-bottom:8px;}
.dash2-q ul li { padding:0 25px 0 0;}
.dash2-q ul li img {cursor: pointer;  width: 30px;  vertical-align: top;}
.dash2-q ul li:last-child img {width: 28px;}
.ipad-responsive { position:relative;}
.ques-time { display:block; position:absolute; right:0; top:0;}
.ques-time-timed-question { display:block; position:absolute; right:0; top:5;padding-right: 5px;}
.text-center.text-none { text-align:center !important;}
.push-end-btn { width: 100%; position:inherit; margin:0 auto; display:block;}
.push-end-btn ul  { text-align:center;}
.push-end-btn ul li{ display:inline-block !important; }
.clr { clear:both;}
.hidethisdiv { display:none;}
.modal-dialog {margin:0;padding-top: 100px;}
.modal-content {margin:0px;}
#page-content-wrapper .pr-4 { padding-right:0 !important;}
#page-content-wrapper .pl-4 { padding-left:0 !important;}
.col.qustion-mob {padding:0;}
.dash {z-index:1000;}
.pt-4.dash2-q {padding-top:0 !important;}
.dash2-q ul li {padding: 0 20px 0 0;}
.radio-btn ul li { width:100%;}
.score-points .col-md-6 {max-width:50%;}
.share-mod .modal-content {padding: 20px 0px;}
.score-points .col-md-6 img { vertical-align: middle; width: 40px;}
.share-mod2 .divh3 h3 { padding: 25px 20px; text-align: center;}
.share-mod2 .pop2-img img {display: block;  margin: 0 auto 0 auto;  width: 150px;}
.share-mod .divh3 h3 {font-size:16px;}
.solutons-p .col-md-9 { max-width:60%;}
.solutons-p .col-md-3 { max-width:40%;}
.good-msg {float:inherit;}
.mob-design  { display:block; padding-bottom:50px;}
.mob-graph .col-md-6{width:100% !important; }
.mob-graph .col-md-6 svg{ width:70%; margin:0 auto; display:block;}
.mob-graph .col-md-3 { width:25% !important; float:left;}
.percent-show-m { position:relative; text-align:center;}
.percent-show { position:absolute; left:122px; top:42px;}
.percent-show ol { list-style:none;}
.percent-show ol li { padding: 14px 0;  font-size: 24px;  font-weight: bold;   color: #5d7bff;}
.percent-show-m ul { list-style:none;  padding: 55px 0 0 20px; margin:0; text-align:left;}
.percent-show-m ul li{font-size: 20px !important;  color: #696565;  width: inherit !important; padding-bottom: 10px; padding-right: 20px;
    font-weight: 100; display: block; line-height:30px; vertical-align: top;}
.percent-show-m ul li span{ display:inline-block; font-size:16px;}
.percent-show-m ul li:nth-child(1) { position: relative;}
.percent-show-m ul li:nth-child(2) { position: relative;}
.percent-show-m ul li:nth-child(3) { position: relative;}
.lst-five-mob h3 {font-size:26px; color:#333;}
.percent-show-m ul li:before {content:''; width: 12px; border-radius:50%; height: 12px;  left: -25px;top: 8px; position:absolute;}
.percent-show-m ul li:nth-child(1):before {content:'';  background-color: #bcd43e;height: 17px;width: 17px;}
.percent-show-m ul li:nth-child(2):before {content:''; background-color: #f7d478; height: 17px;width: 17px;}
.percent-show-m ul li:nth-child(3):before {content:'';   background-color:#fc7fab;height: 17px;width: 17px;}
.percent-show-m ul li .mob-b-g h5 {font-size: 20px; display:inline-block; font-weight: bold;
color: #5d7bff; float:right; padding-right:100px;}
.last-five-gr { padding-bottom:50px;}
.last-five-gr.insight-mob{ padding-bottom:15px;}
.last-five-gr.insight-mob ul li{margin-bottom:20px; position:relative}
.last-five-gr ul{ list-style:none; margin:0; padding:0;}
.last-five-gr ul li{ margin-bottom:30px;}
.last-five-gr .dib{ display:inline-block; vertical-align: top;}
.lst-five-mob.topfive .mob-five-subj {  width:100% !important;}
.last-five-gr.insight-mob .dib {display:inline-block;}
.last-five-gr.insight-mob .dib .mob-strenth-btn { display:block; padding-top:10px;}
.last-five-gr.insight-mob .dib .mob-strenth-btn i.fa.fa-ellipsis-v {
    font-size: 30px;
    padding-top: 30px;
}
.mob-five-msg { padding-bottom:15px; margin-left: 25px; position:relative;}
.mob-five-msg:before { content:''; width:110%; height:1px; background:#ccc; bottom:-8px; position:absolute; margin-left: 0px;}
.last-five-gr ul li:last-child .mob-five-msg:before  { content:''; background:none;}
.react-tabs ul li .mob-strenth-btn  { padding-top:20px;}
.react-tabs ul li .mob-strenth-btn ul li{ width:40%; float:left;}

.mob-five-subj  h4 {font-size: 18px; font-weight:600;  color: #333;}
.result-top-mob ul{padding-left:10px;}
.result-top-mob ul li{display: inline-block; padding-right: 30px; }
.result-top-mob  ul li:nth-child(1) { position: relative;}
.result-top-mob  ul li:nth-child(2) { position: relative;}
.result-top-mob  ul li:nth-child(3) { position: relative;}
.result-top-mob ul li:before { content: '';  width: 15px; height: 15px;  border-radius: 50%;  position: absolute;   left: -25px;    top: 5px;}
.result-top-mob ul li:nth-child(1):before {  background-color: #bcd43e; }
.result-top-mob ul li:nth-child(2):before {content: '';   background-color: #f7d478; }
.result-top-mob ul li:nth-child(3):before { content: '';   background-color: #fc7fab; }
.mob-five-label { position:relative;}
.mob-five-subj { position:relative;}
.mob-five-arrow { position: absolute;  right: -35px;   top: 50%; margin-top:-20px;}
.mob-five-arrow .fa{font-size:20px;}
.mob-five-label h5{font-size: 20px; font-weight:600;  color: #afacac; padding-left:0px; padding-top:10px;}
.last-five-mob-btn button { width:100%; border-radius:6px; box-shadow: #88848417 1px 4px 6px 2px; border:none; background:#fff; font-weight:600;
color:#6245bc; padding:15px 0; }
.review-mob {display:none;}
.mob-filter-review { background:none;}
.reivew-mob-prac h3{font-size: 22px;    color: #333; }
.review-mob-label ul { list-style:none; margin:0; padding:0 0 0 10px;}
.review-mob-label ul li{display: inline-block;font-size:14px; padding-right: 30px;  position: relative;}
.review-mob-label ul li:before { content: '';  width: 10px; height: 10px; background-color: #bcd43e; border-radius: 50%;    position: absolute;
    left: -16px;  top: 4px; border:1px solid #333;}
.review-mob-label ul li:nth-child(2):before { background-color: #f7d478;}
.review-mob-label ul li:nth-child(3):before { background-color: #fc7fab;  }
.mob-five-label h6{font-size: 16px; font-weight:600;  color: #afacac;}
.mob-strenth-btn  { display: block;}
.mob-strenth-btn ul  {margin-left:0;}
.mob-strenth-btn ul li{display:inline-block; margin-right:20px; }
.mob-strenth-btn ul li button{  border-radius: 6px;  box-shadow: #88848442 1px 4px 6px 2px;  border: none;
    background: #fff;  font-weight: 600;  color: #459af7; padding: 12px 40px; width:100%;}
.weekly-mob { display:none;}
.weekly-mod-cont h3 {font-size: 22px; color: #333;}
.week-mob-table table img{ width:50px; height:50px; border-radius:50%;}
.week-mob-table h4{font-size: 16px; font-weight:600;  color: #333333; }
.week-mob-table h4  span{font-size: 14px; display:block; color:#858a87;  font-weight:600; }
.week-acuracy-mob h5  {font-size: 14px; font-weight:600;  color: #333333;}
.week-acuracy-mob  h5  span{color:#858a87;}
.react-tabs ul.react-tabs__tab-list:before {content: '';  height: 1px;  width: 480px;}
.stren-rlt-mob-left  h5{font-size:16px;}
 .stren-rlt-mob-left h5 span{font-size:14px; display:block;}
.stren-rlt-mob-mid { padding:0 5px;}
.mob-tab-perf ul li { box-shadow: #b3b3b32e 0 0 9px 7px;  border-radius: 40px;}
.mob-tab-perf ul li:first-child a{text-align: left; padding-left: 20px;}
.mob-tab-perf ul li:last-child {margin-left: -35px;}
.mob-tab-perf .nav-tabs { border:none; padding-top:20px;}
.mob-tab-perf .nav-item a{ width: 240px; height: 50px;  background: #fff; box-shadow: #b3b3b330 0 0 4px 1px; border-radius: 40px; display: inline-block; line-height: 50px; padding: 0; text-align: center; }
.mob-tab-perf .nav-item .nav-link { color: #333;  font-weight: 800;  border: none; line-height: 50px;
    font-size: 16px;}
a.nav-link.active.show { background: #9d75be; color: #fff; }
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { background: #9d75be; color: #fff; }
.dash .logo { padding:0 0;}
.clock span h1 {font-size:28px;}
.mob-time-band { background: #fff;   width: 100%;  left: 0;  top: 50px;  z-index: 100; padding: 15px 0 0 10px; margin: 0;
 position: fixed;}
.mob-time-band-timed-question { background: #fff;   width: 100%;  left: 0;  top: 0px;  z-index: 10000; padding: 15px 0 0 10px; margin: 0;
    position: fixed;}
 .mob-time-band .ques-time {  display: block;  position: absolute;   right: 12px;   top: 19px;}
.dash-accord .card-body { padding: 20px 0 0 0px;}
.dash-accord  .qustion-mob { padding-top:0px;}
.last-five-gr .mob-five-graph {width:100px; position:relative;}
.last-five-gr .mob-five-graph h5{position: absolute; left: 0; top: 50%;right:0; right:0; margin-left: 0; text-align:center; margin-top: -8px;  font-weight: 600;font-size: 14px;
 color: #000;}
 .react-tabs ul.react-tabs__tab-list li:last-child {display:none;}
 .react-tabs ul.react-tabs__tab-list li {font-size:20px;}
.result-top-mob ul { padding-left: 24px;}
.result-top-mob ul li { margin-bottom:0;}
.mob-strenth-btn { width:100%;}
.last-five-gr.insight-mob ul li:after {content:''; width:100%; height:1px; background:#ccc; left:0; bottom:-25px; position:absolute;}
.stren-rlt-mob-mid.dib { padding: 0 20px;}
.mob-strenth-btn { width:100%;}
.radio-btn ul li { padding-bottom:10px;}
.col.height-mob {margin-bottom: 70px;}
.last-five-gr .mob-five-graph { width:25%;}
.last-five-gr .mob-five-msg {width:67%;}
.multi-sub-mob { position:relative;}
.multi-icon { margin-top:0px;}
.multi-icon h4 .fa { padding-left:10px;}
.multi-icon .fa{ color:#5d7bff; font-size:18px;}
.multi-subs {display:none;}
.multi-subs p{ background:#5d7bff; color:#fff; font-size:16px; padding:10px 10px; border-radius:6px; }
.multi-sub-mob:hover .multi-subs  { display:block;}
.mob-strenth-btn .dropdown { position:absolute; right:-20px; top:0;}
.mob-strenth-btn .dropdown .btn { background:none; border-color:none;}
.mob-strenth-btn  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle
{ background:none; border-color:none; box-shadow:none;}
.mob-strenth-btn .dropdown button:focus {outline:none; box-shadow:none;}
.mob-strenth-btn .dropdown button {background:none; border:none;}
.mob-strenth-btn .dropdown .dropdown-menu{margin-left:-120px}
.css-11unzgr {max-height: 80px !important; padding: 10px 10px;  border: 1px solid #ececec;}
.perf-title {font-size:23px; color:#000; font-weight:600; text-align:left; padding:20px 0 20px 0;}
.mb-d-50{ margin-bottom:50px;}
.mb-d-80{ margin-bottom:80px;}
.mob-side-nav.perm-bot-nav ul li.active:before {content:'';  width:100px; height:3px; background:#462e86; left:50%; top:70px;
 margin-left:-45px; position:absolute;}
 .mob-side-nav.perm-bot-nav ul li a.active{color:#462e86;}
 .graph-large h3{ font-size: 26px; color: #333; text-align:center;}
 .dash-select .css-yk16xz-control{box-shadow:#cccccc42 0 0 7px 3px;}
  .dash-select .multiple-select-custom{box-shadow: #cccccc42 0 0 7px 3px;}
  .head-icon-test h6{font-size:12px; padding-top:5px; display:block;}
  .dash-accord .faq-accord {
    padding: 30px 0;
}
}

@media screen and (max-width: 576px){
.mob-tab-perf .nav-item a {  width: 180px;}
.mob-tab-perf .nav-item .nav-link {font-size: 12px; transition: 0.9s all;}
.react-tabs ul li { transition: .9s ease-in; font-size: 20px;}
.react-tabs__tab { margin-right: 10px !important;}
.st-mob .mob-five-graph img{width:40px;}
.st-mob  .mob-five-msg {margin-left: 5px;}
.st-mob  .stren-rlt-mob-left h5{font-size:16px;}
.mob-strenth-btn ul { margin-left: 10px;}
.mob-strenth-btn ul li { display: block;  margin-right: 10px;}
.dash-right-s .dash-mid-img { padding: 20px 25px;}
.dash-right-s .dash-mid-img h1 {font-size: 25px;}
.dash-right-s .dash-mid-img p { padding-top:10px;}
.last-five-gr .mob-five-graph { width:25%;}
.last-five-gr .mob-five-msg {width:67%;}
.mob-five-msg .mob-five-subj {width:100%; padding-left:0px;}
.mob-five-subj h4 {font-size: 16px; line-height: 25px;}
.result-top-mob ul li {padding-right: 40px}
.result-top-mob {font-size:16px;}
.react-tabs ul li { font-size: 18px;}
.result-top-mob ul {  padding-left: 24px;}
.mob-five-msg {padding-bottom: 0px;  margin-left: 10px; position: relative;}
.react-tabs ul.react-tabs__tab-list li {font-size:16px;}
.mob-five-label h5 {padding-left: 0px;}
.mob-five-label h6 {padding-left: 0px;}
.mob-rev .mob-five-label  { padding-left:20px !important;}


}

@media screen and (max-width: 470px){
.percent-show-m ul li .mob-b-g h5 { padding-right:20px;}
.dash-right-s .dash-mid-img p {font-size:14px; padding-top:0px;}
.react-tabs ul.react-tabs__tab-list:before { content: '';  height: 1px;  width: 100%;}
.react-tabs__tab { margin-right: 5px !important;}
.last-five-gr .mob-five-graph img{ width:30px;}
.mob-five-msg { padding-left: none;}
.mob-five-label h5 {padding-left: 0px;}
.mob-five-label h6 {padding-left: 0px;}
.mob-rev .mob-five-label  { padding-left:20px !important;}
.dash-2-btn .common-btn button {padding: 0 10px; font-size:12px; }
.dash-hov ul li{padding: 0 10px 0 0;}
.dash2-q .mob-side-nav ul li{ display:inline-block; padding:0 35px; position:relative;}
.percent-show-m ul li {font-size:20px;}
 .gp-mob {padding-top: 120px !important;}
 .result-top-mob ul li {padding-right: 30px;}
 .mob-five-label h5 {font-size: 16px;  padding-left: 0px; padding-top: 10px;}

}


@media screen and (max-width: 450px){
.dash2-q .divh4 h4 {color: #a97fcb;  font-size: 16px !important;}
.score-points span { font-size: 12px; vertical-align: middle; display: inline-block;  }

.mob-tab-perf .nav-item a {  width: 160px;}
.mob-tab-perf .nav-item .nav-link {font-size: 10px; transition: 0.9s all;}
.mob-strenth-btn ul li button {padding: 10px 20px;}

}

@media screen and (max-width: 385px){
.dash.pratice-mode .col-md-3 { max-width: 60%;}
.dash .col-md-2.dash-mob {max-width: 60%;}
.score-points span { font-size: 12px; vertical-align: middle; display: inline-block;  }

}
 @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
.height-mob {height:110vh;}
.multiple-select-custom .css-1g6gooi {
    min-width: 280px !important;
}
  }

@media screen and (max-width: 380px){

.dash2-q .mob-side-nav ul li{ display:inline-block; padding:0 35px; position:relative;}

.mob-side-nav ul li a {font-size:12px;}
}
.radio-btn ul li label span .katex-display
{
display: inline-block !important;
}

.footer-logo{
    position: relative;
}

.footer-logo img{
    width:80px;
    right:20px;
    bottom:-150px;
    position:absolute;
}

.editormd-preview-container, .editormd-html-preview{
    padding: 0 !important;
    font-size: 16px !important;
}
.markdown-body p{
    margin-top: 3px !important;
    font-weight: 400;
}

.text-center{
    text-align: center;
}
/* This css add for change model text color */
.mtcolor{
    color: #9374f0;
    font-weight: 500;

}

.verification-banner{
background-color:#5d38db; padding:13px 0; text-align:center;
    margin-bottom: 20px;
}
.verification-banner p {
    text-align: center;
    color: white;
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.verification-banner p span button{
    color:#ddbd36;
    background: none;
    border: none;
}
.markdown-body p{
    margin-top: 3px !important;
    font-weight: 400;
}

.text-center{
    text-align: center;
}
/* This css add for change model text color */
.mtcolor{
    color: #9374f0;
    font-weight: 500;
}


.editormd-preview-container p.editormd-tex, .editormd-html-preview p.editormd-tex {
    text-align: left;
    overflow-y: hidden;
}

  .pointer {cursor: pointer;}
  .markdown-body .editormd-html-preview{
    overflow-y: hidden;
  }

  .contact_modal-body{
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
  }

  .text_color{
      color: #666464;
  }
/* add this css for overlapping purpose by prep prep 1*/
  .modal-backdrop {
    z-index: 100000 !important;
  }
.modal {
    z-index: 100001 !important;
  }

  .MuiToolbar-regular {
    min-height: 56px;
    height: 100%;
}
.h-10 {
    height: 10%;
}
.h-80 {
    height: 80%;
}
.h-full {
    height: 100vh;
}
.rcorners1 {
    border-radius: 10px;
}
.MuiToolbar-gutters {
padding-left: 22px !important;
padding-right: 22px !important;;
}
.word-wrap{
    word-wrap: break-word;
}
.MuiIconButton-root {
    padding: 0 !important;
}
.QuestionIDColor{
    color: #a97fcb;
    font-size: 12px !important;
}
.QuestionIDColorlg{
    color: #a97fcb;
    font-size: 18px !important;
}
.mt-15{
    margin-top: 15%;
}
.lightGray{
    background-color: #C0BFC1 ;
}
.text-black{
    color:black;
    font-size: 1.2rem;
}
.submitNext:active{
    background-color: #06da3b;
}
.submitNext{
  background-color:#28a745;
  
}
.skipbutton{
    background-color:#6610f2;
    line-height:'18px';
}
.skipbutton:active{
    background-color: #5e83d3;
}
.endReview:active{
    background-color: #da3006;
}
.endReview{
  background-color:#da455e;
}
.mt-80{
    margin-top: 80px;
}

.height-10{
    height: 10px;
}
.border-right-colored {
    border-right: 1px solid #a97fcb;;
  }
.font-color-size{
    font-size: 12px;
    color: #a97fcb;;
}

.youans-mob {
   background-color: #bbe5cd;
   border-radius: 40px;
   color: #000;
   font-weight: 600;
   padding: 0;
   width: 150px;
   height: 25px;
   text-align: center;
   line-height: 25px;
   margin: 27px 0 0 auto;
   cursor: pointer;
        }
.youans-mob.correctans { background-color: #fac0bf;}
.youans-mob.skips { background-color: #adaca8;}
.solutons-p-mob {  padding-bottom: 0px}
.good-msg-mob {
    font-weight: 550; 
    font-size: 11px;
}

.payment-option-tile {
    height: 140px;
    width: 100%;
    border-radius: 5px;
    margin: 5px;
    background-color: #CBD3DA;
    color: black;
}

.payment-selected {
    background-color: #409FFF;
    color: white;
    border-radius: 5px;
}

.payment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    height: 100%;
    cursor: pointer;
}

.payment-period {
    font-size: 20px;
    margin: 5px 0px;
}
.payment-amount {
    font-size: 24px;
    margin: 10px 0px;
    margin-top: -10px;
}
.payment-offer {
    font-size: 10px;
    display: block;
}
.payment-period-footer {
    font-size: 10px;
    display: block;
}
.hidden {
    visibility: hidden;
}
.lnheight-10{
    line-height: 10px !important;
}
.prephubIcon{
    position: absolute;
    right: 5px;
    top: 15px;
}
.hw-110{
    height:100px;
    width:100%;
}
.hw-80{
    height:80px;
    width:75px;
}
.bg-gray{
    background-color: #e0e0dc;;
}
.bg-skip{
    background-color: #EED68A;
}
.bg-notVisited{
    background-color: #cdcfd1;
}
.bg-answered{
    background-color: #C0E281;
}
.bg-answeredandBookmarked{
    background: linear-gradient(to bottom right,#C0E281 50%,#EF8888 0 100%);
}
.bg-bookmarked{
    background-color: #EF8888;
}
.assignments .assignment-help-header {
    font-size: 14px;
    margin-bottom: 2px;
}
.assignments .assignment-help-content {
    font-size: 12px;
}
.flex {
    display: flex;
}
.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}
.justify-start {
    justify-content: start;
}
.flex-column {
    flex-direction: column;
}
.reviewCircleStyle{
    box-sizing: content-box;
    text-align: center;
    height:24px;
    width:24px;
    z-index: 10;
}
.skipInSection {
   background-image:linear-gradient(#EED68A, #EED68A) ;
}
.activeQuestion{
    border-color: black;
    border-style: solid;
    height:35px!important;
    width: 35px !important;
}
.notVisitedInSection{
    background:linear-gradient(#cdcfd1, #cdcfd1);
}
.answeredInSection{
    background:linear-gradient(#C0E281, #C0E281);
}
.bookmarkedInSection{
   background:linear-gradient(#EF8888, #EF8888) ;
}
.answeredAndBookmarkedInSection{
    background-image: linear-gradient(to bottom right,#C0E281 50%,#EF8888 0 100%);
}
.sequentialQuestionInSection{
    background-image: linear-gradient(#cdcfd1, #cdcfd1);
}
.sectionQuestionNumberStyle{
    cursor: pointer;
    color: black;
    border-radius: 50%;
    box-sizing: content-box;
    text-align: center;
    height:40px;
    width:40px;
}

.user-circular-images-container {
    position: relative;
    display: flex;
    left: 7px;
}

.user-circular-images {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    border: 2px solid white;
}

.plus-image {
    background-color: black;
    width: 24px;
    height: 24px;
}

.plus-image::before {
    content: "+"; 
    color: white; 
    text-align: center;
    position: relative;
    top: -3px;
    left: 5px;
  }

  .bought-text {
    position: relative;
    left: 69px;
    font-size: 13px;
    top: 2px;
    font-weight: 600
  }

  .photos-container {
      position: relative;
      top: 4px;
  }

  .katex {
      font-size: 16px !important;
  }

.mob-graph .col-md-6 svg{
    width:110%;
    height: 100%;
}
.mobAccGrapfCorrectwithinTime{
    font-size: 12px;
    margin-left: -15px;
}
.ck{font-size: 18px;  font-weight: bold;
    color: #5d7bff;}
.svg:not(:root) {
    width:50%
}
.score-font-heading{
    font-size: 12px;
    color:#565656;
    margin-top: 3px;
    font-weight: 800;
}
.score-font{
    font-size: 12px;
    color:#565656;
    margin-top: 3px;
    font-weight: 400;
}
.last-five-date{
    font-size: 12px;
    color:#565656;
    font-weight: 500;
}
.textOverload {
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
.show-more{
    color:#9d75be;
    font-weight: 800;
    }

.dash2-q ul li p img {
    width: 120px !important;
}

.tabs__bar {
    display: flex;
    align-items: center;
    border-radius: 40px;
    font-size: 14px;
    color: black;
    font-weight: 600;
    background-color: #f7f7f7;
}

.tabs__controls.is-active {
    background-color: #9d74bf;
    color: white;
    font-weight: 600;
}

.tabs__line {
    display: none !important;
}
.tabs__controls {
    min-width: 0px !important;
    width: 120px !important;
    word-wrap: break-word;
    white-space: initial;
    padding: 10px !important;
    height: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-content .mob-graph {
    padding-bottom: 20px;
}
.pb-20p {
    padding-bottom: 20px;
}
    
.MuiTabScrollButton-root.Mui-disabled {
    opacity:0.9 !important;
}
.centerDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.clockImg{
    height:50px;
    width:50px;
}
.questionTypetImg{
    color:#AD7ED2;
}
.logo1 img { width: 200px; display: block; margin:  0 auto 0 auto; padding-top: 20px 0;}

.activeTab{
    color:#a97fcb;
   
}
.btnStyle{
width: 130px; height: 35px; line-height: 20px;
border-radius: 6px; background-color: #666666; padding: 0;color:white;border-color: white;
}
.cursor-pointer {
    cursor: pointer;
}
.assignmentPackHeadingDesktop{
    font-size: 20px;
    color:#81A1B6;
}
.assignmentPackHeadingMobile{
    font-size: 14px;
    color:#81A1B6;
}
.assignmentPackDescriDesktop{
    font-size: 14px;
}
.assignmentPackDescriMobile{
    font-size: 10px;
}
.assignmentPackPriceDesktop{
    font-size: 85%;
}
.assignmentPackPriceMobile{
    font-size: 45%;
}
.border-bottom-left{
    border-bottom-left-radius: 20px;
}
.border-bottom-right{
    border-bottom-right-radius: 20px;
}
.fixedbutton {
    position: fixed;
    top: 400px;
    left: 350px; 
    z-index: 100000;
}
.w-10{
    width:5px;
    font-size: 80px;
    margin-top: 20%;
}
.activeBorder{
    border-style: solid;
    border-width: 4px;
    border-color: black;
    border-radius: 25px;
}
