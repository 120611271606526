.circle_percent {font-size:200px; width:150px; height:150px; position: relative; background: #eee; border-radius:50%; overflow:hidden; display:inline-block; margin:20px;}

.circle_inner {position: absolute; left: 0; top: 0; width: 1em; height: 1em; clip:rect(0 1em 1em .5em);}
.round_per {position: absolute; left: 0; top: 0; width: 1em; height: 1em; background: #e4a6d2; clip:rect(0 1em 1em .5em); transform:rotate(180deg); transition:1.05s;}
.percent_more .circle_inner {clip:rect(0 .5em 1em 0em);}
.percent_more:after {position: absolute; left: .5em; top:0em; right: 0; bottom: 0; background: #e4a6d2; content:'';}
.circle_inbox {position: absolute; top: 10px; left: 10px; right: 10px; bottom: 10px; background: #fff; z-index:3; border-radius: 50%;}
.percent_text {position: absolute; font-size: 30px; left: 50%; top: 50%; transform: translate(-50%,-50%); z-index: 3;}


.scroe-c .scr-result {
    width: 130px;
    border-radius: 6px;
    background-color: #a55fcae0;
    padding: 5px 5px;
    margin-top: -54px;
    z-index: 10000;
    position: relative;
    margin-left: 63px;
}
.scroe-c .scr-result ul{ list-style: none; margin: 0; padding: 0;}
.scroe-c .scr-result ul li {
    padding-bottom: 10px;
    color: #ffd400;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 10px;
}
.scroe-c .scr-result ul li span {
    display: inline-block;
    color: #fff !important;
    font-weight: 500;
    float: right;
    font-size: 12px;
    padding-left: 20px;
}

.scroec-tit {font-size: 17px; font-weight: 800;
    padding-left: 60px; }

.CircularProgressbar {
    width: 100%;
    vertical-align: middle;
    height: 150px;
}