#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
#wrapper.toggled {
    padding-left: 250px;
}
#sidebar-wrapper {
    z-index: 1000;
    position: absolute;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
   /* background: #DB1849;*/
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}
#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}
#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}
.btn-default#menu-toggle, .btn-default#menu-toggle:hover, .btn-default#menu-toggle:focus {
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    color: #DB1849;
}

 .sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav .sidebar-brand img {
    width: 75px;
}

/*.btn-tog {
    background-color: #5d39db;
    width: 6px;
    height: 40px;
    right: 0;
    top: 0;
    margin-top: 300px;
    z-index: 1000;
    position: absolute;
    cursor: pointer;
}*/
/*side bar start*/


@media(min-width:768px) {
    #sidebar-wrapper {width: 70px; height: 100vh; border-right: 1px solid #d0d0d0; position: fixed;  }
    #no-scroll #sidebar-wrapper{height: 92vh!important;}
    #wrapper {padding-left: 70px;    }

.intro #sidebar-wrapper { width: 250px; }
.intro {padding-left: 250px; }
  /*   #wrapper.toggled { padding-left:250px }

      #wrapper.toggled #sidebar-wrapper { width: 250px }
}
}
}
*/


  /*  #wrapper.toggled { padding-left: 0px;    }*/
   /* #wrapper.toggled #sidebar-wrapper {
        width: 70px;}    /* change by me*/

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
        /*padding-left: 80px;*/  /* change by me*/
    }
    #page-content-wrapper {
        padding: 20px;
        position: relative;
        /*z-index: 1000;*/
    }
    /*.intro { padding-left: 250px; }*/
}
